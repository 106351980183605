@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/icomoon.eot?716u6j');
  src:  url('./../fonts/icomoon.eot?716u6j#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon.ttf?716u6j') format('truetype'),
    url('./../fonts/icomoon.woff?716u6j') format('woff'),
    url('./../fonts/icomoon.svg?716u6j#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-applicant-list:before {
  content: "\e900";
}
.icon-attachment2:before {
  content: "\e901";
}
.icon-book2:before {
  content: "\e902";
}
.icon-building:before {
  content: "\e903";
}
.icon-cancel:before {
  content: "\eaef";
}
.icon-chat:before {
  content: "\eaf0";
}
.icon-dashboard:before {
  content: "\eaf1";
}
.icon-delete:before {
  content: "\eaf2";
}
.icon-dollar:before {
  content: "\eaf3";
}
.icon-double-arrow:before {
  content: "\eaf4";
}
.icon-down-arrow:before {
  content: "\eaf5";
}
.icon-down-arrow-1:before {
  content: "\eaf6";
}
.icon-down-arrow-2:before {
  content: "\eaf7";
}
.icon-edit:before {
  content: "\eaf8";
}
.icon-email:before {
  content: "\eaf9";
}
.icon-email-1:before {
  content: "\eafa";
}
.icon-explore-candidates:before {
  content: "\eafb";
}
.icon-eye1:before {
  content: "\eafc";
}
.icon-filter2:before {
  content: "\eafd";
}
.icon-full-time:before {
  content: "\eafe";
}
.icon-hourly-wage:before {
  content: "\eaff";
}
.icon-jobs:before {
  content: "\eb00";
}
.icon-left-arrow:before {
  content: "\eb01";
}
.icon-logo:before {
  content: "\eb02";
}
.icon-logout:before {
  content: "\eb03";
}
.icon-more:before {
  content: "\eb04";
}
.icon-notification1:before {
  content: "\eb05";
}
.icon-onsite:before {
  content: "\eb06";
}
.icon-pen-nidal:before {
  content: "\eb07";
}
.icon-phone2:before {
  content: "\eb08";
}
.icon-right-arrow:before {
  content: "\eb09";
}
.icon-right-arrow-1:before {
  content: "\eb0a";
}
.icon-sales:before {
  content: "\eb0b";
}
.icon-search1:before {
  content: "\eb0c";
}
.icon-settings:before {
  content: "\eb0d";
}
.icon-status-active:before {
  content: "\eb0e";
}
.icon-status-deactivated:before {
  content: "\eb0f";
}
.icon-status-save:before {
  content: "\eb10";
}
.icon-suitcase:before {
  content: "\eb11";
}
.icon-type:before {
  content: "\eb12";
}
.icon-user1:before {
  content: "\eb13";
}
.icon-home1:before {
  content: "\eb29";
}
.icon-home21:before {
  content: "\eb2a";
}
.icon-home31:before {
  content: "\eb2b";
}
.icon-office1:before {
  content: "\eb2c";
}
.icon-newspaper:before {
  content: "\eb2d";
}
.icon-pencil1:before {
  content: "\eb2e";
}
.icon-pencil21:before {
  content: "\eb2f";
}
.icon-quill1:before {
  content: "\eb30";
}
.icon-pen1:before {
  content: "\eb31";
}
.icon-blog1:before {
  content: "\eb32";
}
.icon-eyedropper1:before {
  content: "\eb33";
}
.icon-droplet1:before {
  content: "\eb34";
}
.icon-paint-format1:before {
  content: "\eb35";
}
.icon-image1:before {
  content: "\eb36";
}
.icon-images1:before {
  content: "\eb37";
}
.icon-camera1:before {
  content: "\eb38";
}
.icon-headphones1:before {
  content: "\eb39";
}
.icon-music1:before {
  content: "\eb3a";
}
.icon-play1:before {
  content: "\eb3b";
}
.icon-film1:before {
  content: "\eb3c";
}
.icon-video-camera1:before {
  content: "\eb3d";
}
.icon-dice1:before {
  content: "\eb3e";
}
.icon-pacman1:before {
  content: "\eb3f";
}
.icon-spades1:before {
  content: "\eb40";
}
.icon-clubs1:before {
  content: "\eb41";
}
.icon-diamonds1:before {
  content: "\eb42";
}
.icon-bullhorn1:before {
  content: "\eb43";
}
.icon-connection1:before {
  content: "\eb44";
}
.icon-podcast1:before {
  content: "\eb45";
}
.icon-feed1:before {
  content: "\eb46";
}
.icon-mic1:before {
  content: "\eb47";
}
.icon-book1:before {
  content: "\eb48";
}
.icon-books1:before {
  content: "\eb49";
}
.icon-library1:before {
  content: "\eb4a";
}
.icon-file-text1:before {
  content: "\eb4b";
}
.icon-profile1:before {
  content: "\eb4c";
}
.icon-file-empty1:before {
  content: "\eb4d";
}
.icon-files-empty1:before {
  content: "\eb4e";
}
.icon-file-text21:before {
  content: "\eb4f";
}
.icon-file-picture1:before {
  content: "\eb50";
}
.icon-file-music1:before {
  content: "\eb51";
}
.icon-file-play1:before {
  content: "\eb52";
}
.icon-file-video1:before {
  content: "\eb53";
}
.icon-file-zip1:before {
  content: "\eb54";
}
.icon-copy2:before {
  content: "\eb55";
}
.icon-paste1:before {
  content: "\eb56";
}
.icon-stack1:before {
  content: "\eb57";
}
.icon-folder1:before {
  content: "\eb58";
}
.icon-folder-open1:before {
  content: "\eb59";
}
.icon-folder-plus1:before {
  content: "\eb5a";
}
.icon-folder-minus1:before {
  content: "\eb5b";
}
.icon-folder-download1:before {
  content: "\eb5c";
}
.icon-folder-upload1:before {
  content: "\eb5d";
}
.icon-price-tag1:before {
  content: "\eb5e";
}
.icon-price-tags1:before {
  content: "\eb5f";
}
.icon-barcode1:before {
  content: "\eb60";
}
.icon-qrcode1:before {
  content: "\eb61";
}
.icon-ticket1:before {
  content: "\eb62";
}
.icon-cart1:before {
  content: "\eb63";
}
.icon-coin-dollar1:before {
  content: "\eb64";
}
.icon-coin-euro1:before {
  content: "\eb65";
}
.icon-coin-pound1:before {
  content: "\eb66";
}
.icon-coin-yen1:before {
  content: "\eb67";
}
.icon-credit-card1:before {
  content: "\eb68";
}
.icon-calculator1:before {
  content: "\eb69";
}
.icon-lifebuoy1:before {
  content: "\eb6a";
}
.icon-phone1:before {
  content: "\eb6b";
}
.icon-phone-hang-up1:before {
  content: "\eb6c";
}
.icon-address-book1:before {
  content: "\eb6d";
}
.icon-envelop1:before {
  content: "\eb6e";
}
.icon-pushpin1:before {
  content: "\eb6f";
}
.icon-location1:before {
  content: "\eb70";
}
.icon-location21:before {
  content: "\eb71";
}
.icon-compass1:before {
  content: "\eb72";
}
.icon-compass21:before {
  content: "\eb73";
}
.icon-map1:before {
  content: "\eb74";
}
.icon-map21:before {
  content: "\eb75";
}
.icon-history1:before {
  content: "\eb76";
}
.icon-clock1:before {
  content: "\eb77";
}
.icon-clock21:before {
  content: "\eb78";
}
.icon-alarm1:before {
  content: "\eb79";
}
.icon-bell1:before {
  content: "\eb7a";
}
.icon-stopwatch1:before {
  content: "\eb7b";
}
.icon-calendar2:before {
  content: "\eb7c";
}
.icon-printer1:before {
  content: "\eb7d";
}
.icon-keyboard1:before {
  content: "\eb7e";
}
.icon-display1:before {
  content: "\eb7f";
}
.icon-laptop1:before {
  content: "\eb80";
}
.icon-mobile1:before {
  content: "\eb81";
}
.icon-mobile21:before {
  content: "\eb82";
}
.icon-tablet1:before {
  content: "\eb83";
}
.icon-tv1:before {
  content: "\eb84";
}
.icon-drawer1:before {
  content: "\eb85";
}
.icon-drawer21:before {
  content: "\eb86";
}
.icon-box-add1:before {
  content: "\eb87";
}
.icon-box-remove1:before {
  content: "\eb88";
}
.icon-download1:before {
  content: "\eb89";
}
.icon-upload4:before {
  content: "\eb8a";
}
.icon-floppy-disk1:before {
  content: "\eb8b";
}
.icon-drive1:before {
  content: "\eb8c";
}
.icon-database1:before {
  content: "\eb8d";
}
.icon-undo1:before {
  content: "\eb8e";
}
.icon-redo1:before {
  content: "\eb8f";
}
.icon-undo21:before {
  content: "\eb90";
}
.icon-redo21:before {
  content: "\eb91";
}
.icon-forward1:before {
  content: "\eb92";
}
.icon-reply1:before {
  content: "\eb93";
}
.icon-bubble1:before {
  content: "\eb94";
}
.icon-bubbles1:before {
  content: "\eb95";
}
.icon-bubbles21:before {
  content: "\eb96";
}
.icon-bubble21:before {
  content: "\eb97";
}
.icon-bubbles31:before {
  content: "\eb98";
}
.icon-bubbles41:before {
  content: "\eb99";
}
.icon-user2:before {
  content: "\eb9a";
}
.icon-users1:before {
  content: "\eb9b";
}
.icon-user-plus1:before {
  content: "\eb9c";
}
.icon-user-minus1:before {
  content: "\eb9d";
}
.icon-user-check1:before {
  content: "\eb9e";
}
.icon-user-tie1:before {
  content: "\eb9f";
}
.icon-quotes-left1:before {
  content: "\eba0";
}
.icon-quotes-right1:before {
  content: "\eba1";
}
.icon-hour-glass1:before {
  content: "\eba2";
}
.icon-spinner1:before {
  content: "\eba3";
}
.icon-spinner21:before {
  content: "\eba4";
}
.icon-spinner31:before {
  content: "\eba5";
}
.icon-spinner41:before {
  content: "\eba6";
}
.icon-spinner51:before {
  content: "\eba7";
}
.icon-spinner61:before {
  content: "\eba8";
}
.icon-spinner71:before {
  content: "\eba9";
}
.icon-spinner81:before {
  content: "\ebaa";
}
.icon-spinner91:before {
  content: "\ebab";
}
.icon-spinner101:before {
  content: "\ebac";
}
.icon-spinner111:before {
  content: "\ebad";
}
.icon-binoculars1:before {
  content: "\ebae";
}
.icon-search2:before {
  content: "\ebaf";
}
.icon-zoom-in1:before {
  content: "\ebb0";
}
.icon-zoom-out1:before {
  content: "\ebb1";
}
.icon-enlarge1:before {
  content: "\ebb2";
}
.icon-shrink1:before {
  content: "\ebb3";
}
.icon-enlarge21:before {
  content: "\ebb4";
}
.icon-shrink21:before {
  content: "\ebb5";
}
.icon-key1:before {
  content: "\ebb6";
}
.icon-key21:before {
  content: "\ebb7";
}
.icon-lock1:before {
  content: "\ebb8";
}
.icon-unlocked1:before {
  content: "\ebb9";
}
.icon-wrench1:before {
  content: "\ebba";
}
.icon-equalizer1:before {
  content: "\ebbb";
}
.icon-equalizer21:before {
  content: "\ebbc";
}
.icon-cog1:before {
  content: "\ebbd";
}
.icon-cogs1:before {
  content: "\ebbe";
}
.icon-hammer1:before {
  content: "\ebbf";
}
.icon-magic-wand1:before {
  content: "\ebc0";
}
.icon-aid-kit1:before {
  content: "\ebc1";
}
.icon-bug1:before {
  content: "\ebc2";
}
.icon-pie-chart2:before {
  content: "\ebc3";
}
.icon-stats-dots1:before {
  content: "\ebc4";
}
.icon-stats-bars1:before {
  content: "\ebc5";
}
.icon-stats-bars21:before {
  content: "\ebc6";
}
.icon-trophy1:before {
  content: "\ebc7";
}
.icon-gift1:before {
  content: "\ebc8";
}
.icon-glass1:before {
  content: "\ebc9";
}
.icon-glass21:before {
  content: "\ebca";
}
.icon-mug1:before {
  content: "\ebcb";
}
.icon-spoon-knife1:before {
  content: "\ebcc";
}
.icon-leaf1:before {
  content: "\ebcd";
}
.icon-rocket1:before {
  content: "\ebce";
}
.icon-meter1:before {
  content: "\ebcf";
}
.icon-meter21:before {
  content: "\ebd0";
}
.icon-hammer21:before {
  content: "\ebd1";
}
.icon-fire1:before {
  content: "\ebd2";
}
.icon-lab1:before {
  content: "\ebd3";
}
.icon-magnet1:before {
  content: "\ebd4";
}
.icon-bin1:before {
  content: "\ebd5";
}
.icon-bin21:before {
  content: "\ebd6";
}
.icon-briefcase1:before {
  content: "\ebd7";
}
.icon-airplane1:before {
  content: "\ebd8";
}
.icon-truck1:before {
  content: "\ebd9";
}
.icon-road1:before {
  content: "\ebda";
}
.icon-accessibility1:before {
  content: "\ebdb";
}
.icon-target1:before {
  content: "\ebdc";
}
.icon-shield1:before {
  content: "\ebdd";
}
.icon-power1:before {
  content: "\ebde";
}
.icon-switch1:before {
  content: "\ebdf";
}
.icon-power-cord1:before {
  content: "\ebe0";
}
.icon-clipboard1:before {
  content: "\ebe1";
}
.icon-list-numbered1:before {
  content: "\ebe2";
}
.icon-list1:before {
  content: "\ebe3";
}
.icon-list21:before {
  content: "\ebe4";
}
.icon-tree1:before {
  content: "\ebe5";
}
.icon-menu6:before {
  content: "\ebe6";
}
.icon-menu21:before {
  content: "\ebe7";
}
.icon-menu31:before {
  content: "\ebe8";
}
.icon-menu41:before {
  content: "\ebe9";
}
.icon-cloud1:before {
  content: "\ebea";
}
.icon-cloud-download1:before {
  content: "\ebeb";
}
.icon-cloud-upload1:before {
  content: "\ebec";
}
.icon-cloud-check1:before {
  content: "\ebed";
}
.icon-download21:before {
  content: "\ebee";
}
.icon-upload21:before {
  content: "\ebef";
}
.icon-download31:before {
  content: "\ebf0";
}
.icon-upload31:before {
  content: "\ebf1";
}
.icon-sphere1:before {
  content: "\ebf2";
}
.icon-earth1:before {
  content: "\ebf3";
}
.icon-link1:before {
  content: "\ebf4";
}
.icon-flag1:before {
  content: "\ebf5";
}
.icon-attachment1:before {
  content: "\ebf6";
}
.icon-eye2:before {
  content: "\ebf7";
}
.icon-eye-plus1:before {
  content: "\ebf8";
}
.icon-eye-minus1:before {
  content: "\ebf9";
}
.icon-eye-blocked1:before {
  content: "\ebfa";
}
.icon-bookmark1:before {
  content: "\ebfb";
}
.icon-bookmarks1:before {
  content: "\ebfc";
}
.icon-sun1:before {
  content: "\ebfd";
}
.icon-contrast1:before {
  content: "\ebfe";
}
.icon-brightness-contrast1:before {
  content: "\ebff";
}
.icon-star-empty1:before {
  content: "\ec00";
}
.icon-star-half1:before {
  content: "\ec01";
}
.icon-star-full1:before {
  content: "\ec02";
}
.icon-heart1:before {
  content: "\ec03";
}
.icon-heart-broken1:before {
  content: "\ec04";
}
.icon-man1:before {
  content: "\ec05";
}
.icon-woman1:before {
  content: "\ec06";
}
.icon-man-woman1:before {
  content: "\ec07";
}
.icon-happy1:before {
  content: "\ec08";
}
.icon-happy21:before {
  content: "\ec09";
}
.icon-smile1:before {
  content: "\ec0a";
}
.icon-smile21:before {
  content: "\ec0b";
}
.icon-tongue1:before {
  content: "\ec0c";
}
.icon-tongue21:before {
  content: "\ec0d";
}
.icon-sad1:before {
  content: "\ec0e";
}
.icon-sad21:before {
  content: "\ec0f";
}
.icon-wink1:before {
  content: "\ec10";
}
.icon-wink21:before {
  content: "\ec11";
}
.icon-grin1:before {
  content: "\ec12";
}
.icon-grin21:before {
  content: "\ec13";
}
.icon-cool1:before {
  content: "\ec14";
}
.icon-cool21:before {
  content: "\ec15";
}
.icon-angry1:before {
  content: "\ec16";
}
.icon-angry21:before {
  content: "\ec17";
}
.icon-evil1:before {
  content: "\ec18";
}
.icon-evil21:before {
  content: "\ec19";
}
.icon-shocked1:before {
  content: "\ec1a";
}
.icon-shocked21:before {
  content: "\ec1b";
}
.icon-baffled1:before {
  content: "\ec1c";
}
.icon-baffled21:before {
  content: "\ec1d";
}
.icon-confused1:before {
  content: "\ec1e";
}
.icon-confused21:before {
  content: "\ec1f";
}
.icon-neutral1:before {
  content: "\ec20";
}
.icon-neutral21:before {
  content: "\ec21";
}
.icon-hipster1:before {
  content: "\ec22";
}
.icon-hipster21:before {
  content: "\ec23";
}
.icon-wondering1:before {
  content: "\ec24";
}
.icon-wondering21:before {
  content: "\ec25";
}
.icon-sleepy1:before {
  content: "\ec26";
}
.icon-sleepy21:before {
  content: "\ec27";
}
.icon-frustrated1:before {
  content: "\ec28";
}
.icon-frustrated21:before {
  content: "\ec29";
}
.icon-crying1:before {
  content: "\ec2a";
}
.icon-crying21:before {
  content: "\ec2b";
}
.icon-point-up1:before {
  content: "\ec2c";
}
.icon-point-right1:before {
  content: "\ec2d";
}
.icon-point-down1:before {
  content: "\ec2e";
}
.icon-point-left1:before {
  content: "\ec2f";
}
.icon-warning1:before {
  content: "\ec30";
}
.icon-notification2:before {
  content: "\ec31";
}
.icon-question1:before {
  content: "\ec32";
}
.icon-plus2:before {
  content: "\ec33";
}
.icon-minus1:before {
  content: "\ec34";
}
.icon-info1:before {
  content: "\ec35";
}
.icon-cancel-circle1:before {
  content: "\ec36";
}
.icon-blocked1:before {
  content: "\ec37";
}
.icon-cross1:before {
  content: "\ec38";
}
.icon-checkmark1:before {
  content: "\ec39";
}
.icon-checkmark21:before {
  content: "\ec3a";
}
.icon-spell-check1:before {
  content: "\ec3b";
}
.icon-enter1:before {
  content: "\ec3c";
}
.icon-exit1:before {
  content: "\ec3d";
}
.icon-play21:before {
  content: "\ec3e";
}
.icon-pause1:before {
  content: "\ec3f";
}
.icon-stop3:before {
  content: "\ec40";
}
.icon-previous1:before {
  content: "\ec41";
}
.icon-next1:before {
  content: "\ec42";
}
.icon-backward1:before {
  content: "\ec43";
}
.icon-forward21:before {
  content: "\ec44";
}
.icon-play31:before {
  content: "\ec45";
}
.icon-pause21:before {
  content: "\ec46";
}
.icon-stop21:before {
  content: "\ec47";
}
.icon-backward21:before {
  content: "\ec48";
}
.icon-forward31:before {
  content: "\ec49";
}
.icon-first1:before {
  content: "\ec4a";
}
.icon-last1:before {
  content: "\ec4b";
}
.icon-previous21:before {
  content: "\ec4c";
}
.icon-next21:before {
  content: "\ec4d";
}
.icon-eject1:before {
  content: "\ec4e";
}
.icon-volume-high1:before {
  content: "\ec4f";
}
.icon-volume-medium1:before {
  content: "\ec50";
}
.icon-volume-low1:before {
  content: "\ec51";
}
.icon-volume-mute1:before {
  content: "\ec52";
}
.icon-volume-mute21:before {
  content: "\ec53";
}
.icon-volume-increase1:before {
  content: "\ec54";
}
.icon-volume-decrease1:before {
  content: "\ec55";
}
.icon-loop1:before {
  content: "\ec56";
}
.icon-loop21:before {
  content: "\ec57";
}
.icon-infinite1:before {
  content: "\ec58";
}
.icon-shuffle1:before {
  content: "\ec59";
}
.icon-arrow-up-left1:before {
  content: "\ec5a";
}
.icon-arrow-up1:before {
  content: "\ec5b";
}
.icon-arrow-up-right1:before {
  content: "\ec5c";
}
.icon-arrow-right1:before {
  content: "\ec5d";
}
.icon-arrow-down-right1:before {
  content: "\ec5e";
}
.icon-arrow-down1:before {
  content: "\ec5f";
}
.icon-arrow-down-left1:before {
  content: "\ec60";
}
.icon-arrow-left1:before {
  content: "\ec61";
}
.icon-arrow-up-left21:before {
  content: "\ec62";
}
.icon-arrow-up21:before {
  content: "\ec63";
}
.icon-arrow-up-right21:before {
  content: "\ec64";
}
.icon-arrow-right21:before {
  content: "\ec65";
}
.icon-arrow-down-right21:before {
  content: "\ec66";
}
.icon-arrow-down21:before {
  content: "\ec67";
}
.icon-arrow-down-left21:before {
  content: "\ec68";
}
.icon-arrow-left21:before {
  content: "\ec69";
}
.icon-circle-up1:before {
  content: "\ec6a";
}
.icon-circle-right1:before {
  content: "\ec6b";
}
.icon-circle-down1:before {
  content: "\ec6c";
}
.icon-circle-left1:before {
  content: "\ec6d";
}
.icon-tab1:before {
  content: "\ec6e";
}
.icon-move-up1:before {
  content: "\ec6f";
}
.icon-move-down1:before {
  content: "\ec70";
}
.icon-sort-alpha-asc1:before {
  content: "\ec71";
}
.icon-sort-alpha-desc1:before {
  content: "\ec72";
}
.icon-sort-numeric-asc1:before {
  content: "\ec73";
}
.icon-sort-numberic-desc1:before {
  content: "\ec74";
}
.icon-sort-amount-asc1:before {
  content: "\ec75";
}
.icon-sort-amount-desc1:before {
  content: "\ec76";
}
.icon-command1:before {
  content: "\ec77";
}
.icon-shift1:before {
  content: "\ec78";
}
.icon-ctrl1:before {
  content: "\ec79";
}
.icon-opt1:before {
  content: "\ec7a";
}
.icon-checkbox-checked1:before {
  content: "\ec7b";
}
.icon-checkbox-unchecked1:before {
  content: "\ec7c";
}
.icon-radio-checked1:before {
  content: "\ec7d";
}
.icon-radio-checked21:before {
  content: "\ec7e";
}
.icon-radio-unchecked1:before {
  content: "\ec7f";
}
.icon-crop1:before {
  content: "\ec80";
}
.icon-make-group1:before {
  content: "\ec81";
}
.icon-ungroup1:before {
  content: "\ec82";
}
.icon-scissors1:before {
  content: "\ec83";
}
.icon-filter1:before {
  content: "\ec84";
}
.icon-font1:before {
  content: "\ec85";
}
.icon-ligature1:before {
  content: "\ec86";
}
.icon-ligature21:before {
  content: "\ec87";
}
.icon-text-height1:before {
  content: "\ec88";
}
.icon-text-width1:before {
  content: "\ec89";
}
.icon-font-size1:before {
  content: "\ec8a";
}
.icon-bold1:before {
  content: "\ec8b";
}
.icon-underline1:before {
  content: "\ec8c";
}
.icon-italic1:before {
  content: "\ec8d";
}
.icon-strikethrough1:before {
  content: "\ec8e";
}
.icon-omega1:before {
  content: "\ec8f";
}
.icon-sigma1:before {
  content: "\ec90";
}
.icon-page-break1:before {
  content: "\ec91";
}
.icon-superscript1:before {
  content: "\ec92";
}
.icon-subscript1:before {
  content: "\ec93";
}
.icon-superscript21:before {
  content: "\ec94";
}
.icon-subscript21:before {
  content: "\ec95";
}
.icon-text-color1:before {
  content: "\ec96";
}
.icon-pagebreak1:before {
  content: "\ec97";
}
.icon-clear-formatting1:before {
  content: "\ec98";
}
.icon-table1:before {
  content: "\ec99";
}
.icon-table21:before {
  content: "\ec9a";
}
.icon-insert-template1:before {
  content: "\ec9b";
}
.icon-pilcrow1:before {
  content: "\ec9c";
}
.icon-ltr1:before {
  content: "\ec9d";
}
.icon-rtl1:before {
  content: "\ec9e";
}
.icon-section1:before {
  content: "\ec9f";
}
.icon-paragraph-left1:before {
  content: "\eca0";
}
.icon-paragraph-center1:before {
  content: "\eca1";
}
.icon-paragraph-right1:before {
  content: "\eca2";
}
.icon-paragraph-justify1:before {
  content: "\eca3";
}
.icon-indent-increase1:before {
  content: "\eca4";
}
.icon-indent-decrease1:before {
  content: "\eca5";
}
.icon-share1:before {
  content: "\eca6";
}
.icon-new-tab1:before {
  content: "\eca7";
}
.icon-embed1:before {
  content: "\eca8";
}
.icon-embed21:before {
  content: "\eca9";
}
.icon-terminal1:before {
  content: "\ecaa";
}
.icon-share21:before {
  content: "\ecab";
}
.icon-mail1:before {
  content: "\ecac";
}
.icon-mail21:before {
  content: "\ecad";
}
.icon-mail31:before {
  content: "\ecae";
}
.icon-mail41:before {
  content: "\ecaf";
}
.icon-amazon1:before {
  content: "\ecb0";
}
.icon-google4:before {
  content: "\ecb1";
}
.icon-google21:before {
  content: "\ecb2";
}
.icon-google31:before {
  content: "\ecb3";
}
.icon-google-plus1:before {
  content: "\ecb4";
}
.icon-google-plus21:before {
  content: "\ecb5";
}
.icon-google-plus31:before {
  content: "\ecb6";
}
.icon-hangouts1:before {
  content: "\ecb7";
}
.icon-google-drive1:before {
  content: "\ecb8";
}
.icon-facebook3:before {
  content: "\ecb9";
}
.icon-facebook21:before {
  content: "\ecba";
}
.icon-instagram3:before {
  content: "\ecbb";
}
.icon-whatsapp1:before {
  content: "\ecbc";
}
.icon-spotify1:before {
  content: "\ecbd";
}
.icon-telegram1:before {
  content: "\ecbe";
}
.icon-twitter3:before {
  content: "\ecbf";
}
.icon-vine1:before {
  content: "\ecc0";
}
.icon-vk1:before {
  content: "\ecc1";
}
.icon-renren1:before {
  content: "\ecc2";
}
.icon-sina-weibo1:before {
  content: "\ecc3";
}
.icon-rss1:before {
  content: "\ecc4";
}
.icon-rss21:before {
  content: "\ecc5";
}
.icon-youtube3:before {
  content: "\ecc6";
}
.icon-youtube21:before {
  content: "\ecc7";
}
.icon-twitch1:before {
  content: "\ecc8";
}
.icon-vimeo1:before {
  content: "\ecc9";
}
.icon-vimeo21:before {
  content: "\ecca";
}
.icon-lanyrd1:before {
  content: "\eccb";
}
.icon-flickr1:before {
  content: "\eccc";
}
.icon-flickr21:before {
  content: "\eccd";
}
.icon-flickr31:before {
  content: "\ecce";
}
.icon-flickr41:before {
  content: "\eccf";
}
.icon-dribbble1:before {
  content: "\ecd0";
}
.icon-behance1:before {
  content: "\ecd1";
}
.icon-behance21:before {
  content: "\ecd2";
}
.icon-deviantart1:before {
  content: "\ecd3";
}
.icon-500px1:before {
  content: "\ecd4";
}
.icon-steam1:before {
  content: "\ecd5";
}
.icon-steam21:before {
  content: "\ecd6";
}
.icon-dropbox1:before {
  content: "\ecd7";
}
.icon-onedrive1:before {
  content: "\ecd8";
}
.icon-github1:before {
  content: "\ecd9";
}
.icon-npm1:before {
  content: "\ecda";
}
.icon-basecamp1:before {
  content: "\ecdb";
}
.icon-trello1:before {
  content: "\ecdc";
}
.icon-wordpress1:before {
  content: "\ecdd";
}
.icon-joomla1:before {
  content: "\ecde";
}
.icon-ello1:before {
  content: "\ecdf";
}
.icon-blogger1:before {
  content: "\ece0";
}
.icon-blogger21:before {
  content: "\ece1";
}
.icon-tumblr1:before {
  content: "\ece2";
}
.icon-tumblr21:before {
  content: "\ece3";
}
.icon-yahoo1:before {
  content: "\ece4";
}
.icon-yahoo21:before {
  content: "\ece5";
}
.icon-tux1:before {
  content: "\ece6";
}
.icon-appleinc1:before {
  content: "\ece7";
}
.icon-finder1:before {
  content: "\ece8";
}
.icon-android1:before {
  content: "\ece9";
}
.icon-windows1:before {
  content: "\ecea";
}
.icon-windows81:before {
  content: "\eceb";
}
.icon-soundcloud1:before {
  content: "\ecec";
}
.icon-soundcloud21:before {
  content: "\eced";
}
.icon-skype1:before {
  content: "\ecee";
}
.icon-reddit1:before {
  content: "\ecef";
}
.icon-hackernews1:before {
  content: "\ecf0";
}
.icon-wikipedia1:before {
  content: "\ecf1";
}
.icon-linkedin3:before {
  content: "\ecf2";
}
.icon-linkedin21:before {
  content: "\ecf3";
}
.icon-lastfm1:before {
  content: "\ecf4";
}
.icon-lastfm21:before {
  content: "\ecf5";
}
.icon-delicious1:before {
  content: "\ecf6";
}
.icon-stumbleupon1:before {
  content: "\ecf7";
}
.icon-stumbleupon21:before {
  content: "\ecf8";
}
.icon-stackoverflow1:before {
  content: "\ecf9";
}
.icon-pinterest3:before {
  content: "\ecfa";
}
.icon-pinterest21:before {
  content: "\ecfb";
}
.icon-xing1:before {
  content: "\ecfc";
}
.icon-xing21:before {
  content: "\ecfd";
}
.icon-flattr1:before {
  content: "\ecfe";
}
.icon-foursquare1:before {
  content: "\ecff";
}
.icon-yelp1:before {
  content: "\ed00";
}
.icon-paypal1:before {
  content: "\ed01";
}
.icon-chrome1:before {
  content: "\ed02";
}
.icon-firefox1:before {
  content: "\ed03";
}
.icon-IE1:before {
  content: "\ed04";
}
.icon-edge1:before {
  content: "\ed05";
}
.icon-safari1:before {
  content: "\ed06";
}
.icon-opera1:before {
  content: "\ed07";
}
.icon-file-pdf1:before {
  content: "\ed08";
}
.icon-file-openoffice1:before {
  content: "\ed09";
}
.icon-file-word1:before {
  content: "\ed0a";
}
.icon-file-excel1:before {
  content: "\ed0b";
}
.icon-libreoffice1:before {
  content: "\ed0c";
}
.icon-html-five1:before {
  content: "\ed0d";
}
.icon-html-five21:before {
  content: "\ed0e";
}
.icon-css31:before {
  content: "\ed0f";
}
.icon-git1:before {
  content: "\ed10";
}
.icon-codepen1:before {
  content: "\ed11";
}
.icon-svg1:before {
  content: "\ed12";
}
.icon-IcoMoon1:before {
  content: "\ed13";
}
.icon-home:before {
  content: "\e904";
}
.icon-home2:before {
  content: "\e905";
}
.icon-home3:before {
  content: "\e906";
}
.icon-office:before {
  content: "\e907";
}
.icon-newspaper1:before {
  content: "\e908";
}
.icon-pencil:before {
  content: "\e909";
}
.icon-pencil2:before {
  content: "\e90a";
}
.icon-quill:before {
  content: "\e90b";
}
.icon-pen:before {
  content: "\e90c";
}
.icon-blog:before {
  content: "\e90d";
}
.icon-eyedropper:before {
  content: "\e90e";
}
.icon-droplet:before {
  content: "\e90f";
}
.icon-paint-format:before {
  content: "\e910";
}
.icon-image:before {
  content: "\e911";
}
.icon-images:before {
  content: "\e912";
}
.icon-camera:before {
  content: "\e913";
}
.icon-headphones:before {
  content: "\e914";
}
.icon-music:before {
  content: "\e915";
}
.icon-play:before {
  content: "\e916";
}
.icon-film:before {
  content: "\e917";
}
.icon-video-camera:before {
  content: "\e918";
}
.icon-dice:before {
  content: "\e919";
}
.icon-pacman:before {
  content: "\e91a";
}
.icon-spades:before {
  content: "\e91b";
}
.icon-clubs:before {
  content: "\e91c";
}
.icon-diamonds:before {
  content: "\e91d";
}
.icon-bullhorn:before {
  content: "\e91e";
}
.icon-connection:before {
  content: "\e91f";
}
.icon-podcast:before {
  content: "\e920";
}
.icon-feed:before {
  content: "\e921";
}
.icon-mic:before {
  content: "\e922";
}
.icon-book:before {
  content: "\e923";
}
.icon-books:before {
  content: "\e924";
}
.icon-library:before {
  content: "\e925";
}
.icon-file-text:before {
  content: "\e926";
}
.icon-profile:before {
  content: "\e927";
}
.icon-file-empty:before {
  content: "\e928";
}
.icon-files-empty:before {
  content: "\e929";
}
.icon-file-text2:before {
  content: "\e92a";
}
.icon-file-picture:before {
  content: "\e92b";
}
.icon-file-music:before {
  content: "\e92c";
}
.icon-file-play:before {
  content: "\e92d";
}
.icon-file-video:before {
  content: "\e92e";
}
.icon-file-zip:before {
  content: "\e92f";
}
.icon-copy:before {
  content: "\e930";
}
.icon-paste:before {
  content: "\e931";
}
.icon-stack:before {
  content: "\e932";
}
.icon-folder:before {
  content: "\e933";
}
.icon-folder-open:before {
  content: "\e934";
}
.icon-folder-plus:before {
  content: "\e935";
}
.icon-folder-minus:before {
  content: "\e936";
}
.icon-folder-download:before {
  content: "\e937";
}
.icon-folder-upload:before {
  content: "\e938";
}
.icon-price-tag:before {
  content: "\e939";
}
.icon-price-tags:before {
  content: "\e93a";
}
.icon-barcode:before {
  content: "\e93b";
}
.icon-qrcode:before {
  content: "\e93c";
}
.icon-ticket:before {
  content: "\e93d";
}
.icon-cart:before {
  content: "\e93e";
}
.icon-coin-dollar:before {
  content: "\e93f";
}
.icon-coin-euro:before {
  content: "\e940";
}
.icon-coin-pound:before {
  content: "\e941";
}
.icon-coin-yen:before {
  content: "\e942";
}
.icon-credit-card:before {
  content: "\e943";
}
.icon-calculator:before {
  content: "\e944";
}
.icon-lifebuoy:before {
  content: "\e945";
}
.icon-phone:before {
  content: "\e946";
}
.icon-phone-hang-up:before {
  content: "\e947";
}
.icon-address-book:before {
  content: "\e948";
}
.icon-envelop:before {
  content: "\e949";
}
.icon-pushpin:before {
  content: "\e94a";
}
.icon-location:before {
  content: "\e94b";
}
.icon-location2:before {
  content: "\e94c";
}
.icon-compass:before {
  content: "\e94d";
}
.icon-compass2:before {
  content: "\e94e";
}
.icon-map:before {
  content: "\e94f";
}
.icon-map2:before {
  content: "\e950";
}
.icon-history:before {
  content: "\e951";
}
.icon-clock:before {
  content: "\e952";
}
.icon-clock2:before {
  content: "\e953";
}
.icon-alarm:before {
  content: "\e954";
}
.icon-bell:before {
  content: "\e955";
}
.icon-stopwatch:before {
  content: "\e956";
}
.icon-calendar:before {
  content: "\e957";
}
.icon-printer:before {
  content: "\e958";
}
.icon-keyboard:before {
  content: "\e959";
}
.icon-display:before {
  content: "\e95a";
}
.icon-laptop:before {
  content: "\e95b";
}
.icon-mobile:before {
  content: "\e95c";
}
.icon-mobile2:before {
  content: "\e95d";
}
.icon-tablet:before {
  content: "\e95e";
}
.icon-tv:before {
  content: "\e95f";
}
.icon-drawer:before {
  content: "\e960";
}
.icon-drawer2:before {
  content: "\e961";
}
.icon-box-add:before {
  content: "\e962";
}
.icon-box-remove:before {
  content: "\e963";
}
.icon-download:before {
  content: "\e964";
}
.icon-upload:before {
  content: "\e965";
}
.icon-floppy-disk:before {
  content: "\e966";
}
.icon-drive:before {
  content: "\e967";
}
.icon-database:before {
  content: "\e968";
}
.icon-undo:before {
  content: "\e969";
}
.icon-redo:before {
  content: "\e96a";
}
.icon-undo2:before {
  content: "\e96b";
}
.icon-redo2:before {
  content: "\e96c";
}
.icon-forward:before {
  content: "\e96d";
}
.icon-reply:before {
  content: "\e96e";
}
.icon-bubble:before {
  content: "\e96f";
}
.icon-bubbles:before {
  content: "\e970";
}
.icon-bubbles2:before {
  content: "\e971";
}
.icon-bubble2:before {
  content: "\e972";
}
.icon-bubbles3:before {
  content: "\e973";
}
.icon-bubbles4:before {
  content: "\e974";
}
.icon-user:before {
  content: "\e975";
}
.icon-users:before {
  content: "\e976";
}
.icon-user-plus:before {
  content: "\e977";
}
.icon-user-minus:before {
  content: "\e978";
}
.icon-user-check:before {
  content: "\e979";
}
.icon-user-tie:before {
  content: "\e97a";
}
.icon-quotes-left:before {
  content: "\e97b";
}
.icon-quotes-right:before {
  content: "\e97c";
}
.icon-hour-glass:before {
  content: "\e97d";
}
.icon-spinner:before {
  content: "\e97e";
}
.icon-spinner2:before {
  content: "\e97f";
}
.icon-spinner3:before {
  content: "\e980";
}
.icon-spinner4:before {
  content: "\e981";
}
.icon-spinner5:before {
  content: "\e982";
}
.icon-spinner6:before {
  content: "\e983";
}
.icon-spinner7:before {
  content: "\e984";
}
.icon-spinner8:before {
  content: "\e985";
}
.icon-spinner9:before {
  content: "\e986";
}
.icon-spinner10:before {
  content: "\e987";
}
.icon-spinner11:before {
  content: "\e988";
}
.icon-binoculars:before {
  content: "\e989";
}
.icon-search:before {
  content: "\e98a";
}
.icon-zoom-in:before {
  content: "\e98b";
}
.icon-zoom-out:before {
  content: "\e98c";
}
.icon-enlarge:before {
  content: "\e98d";
}
.icon-shrink:before {
  content: "\e98e";
}
.icon-enlarge2:before {
  content: "\e98f";
}
.icon-shrink2:before {
  content: "\e990";
}
.icon-key:before {
  content: "\e991";
}
.icon-key2:before {
  content: "\e992";
}
.icon-lock:before {
  content: "\e993";
}
.icon-unlocked:before {
  content: "\e994";
}
.icon-wrench:before {
  content: "\e995";
}
.icon-equalizer:before {
  content: "\e996";
}
.icon-equalizer2:before {
  content: "\e997";
}
.icon-cog:before {
  content: "\e998";
}
.icon-cogs:before {
  content: "\e999";
}
.icon-hammer:before {
  content: "\e99a";
}
.icon-magic-wand:before {
  content: "\e99b";
}
.icon-aid-kit:before {
  content: "\e99c";
}
.icon-bug:before {
  content: "\e99d";
}
.icon-pie-chart:before {
  content: "\e99e";
}
.icon-stats-dots:before {
  content: "\e99f";
}
.icon-stats-bars:before {
  content: "\e9a0";
}
.icon-stats-bars2:before {
  content: "\e9a1";
}
.icon-trophy:before {
  content: "\e9a2";
}
.icon-gift:before {
  content: "\e9a3";
}
.icon-glass:before {
  content: "\e9a4";
}
.icon-glass2:before {
  content: "\e9a5";
}
.icon-mug:before {
  content: "\e9a6";
}
.icon-spoon-knife:before {
  content: "\e9a7";
}
.icon-leaf:before {
  content: "\e9a8";
}
.icon-rocket:before {
  content: "\e9a9";
}
.icon-meter:before {
  content: "\e9aa";
}
.icon-meter2:before {
  content: "\e9ab";
}
.icon-hammer2:before {
  content: "\e9ac";
}
.icon-fire:before {
  content: "\e9ad";
}
.icon-lab:before {
  content: "\e9ae";
}
.icon-magnet:before {
  content: "\e9af";
}
.icon-bin:before {
  content: "\e9b0";
}
.icon-bin2:before {
  content: "\e9b1";
}
.icon-briefcase:before {
  content: "\e9b2";
}
.icon-airplane:before {
  content: "\e9b3";
}
.icon-truck:before {
  content: "\e9b4";
}
.icon-road:before {
  content: "\e9b5";
}
.icon-accessibility:before {
  content: "\e9b6";
}
.icon-target:before {
  content: "\e9b7";
}
.icon-shield:before {
  content: "\e9b8";
}
.icon-power:before {
  content: "\e9b9";
}
.icon-switch:before {
  content: "\e9ba";
}
.icon-power-cord:before {
  content: "\e9bb";
}
.icon-clipboard:before {
  content: "\e9bc";
}
.icon-list-numbered:before {
  content: "\e9bd";
}
.icon-list:before {
  content: "\e9be";
}
.icon-list2:before {
  content: "\e9bf";
}
.icon-tree:before {
  content: "\e9c0";
}
.icon-menu:before {
  content: "\e9c1";
}
.icon-menu2:before {
  content: "\e9c2";
}
.icon-menu3:before {
  content: "\e9c3";
}
.icon-menu4:before {
  content: "\e9c4";
}
.icon-cloud:before {
  content: "\e9c5";
}
.icon-cloud-download:before {
  content: "\e9c6";
}
.icon-cloud-upload:before {
  content: "\e9c7";
}
.icon-cloud-check:before {
  content: "\e9c8";
}
.icon-download2:before {
  content: "\e9c9";
}
.icon-upload2:before {
  content: "\e9ca";
}
.icon-download3:before {
  content: "\e9cb";
}
.icon-upload3:before {
  content: "\e9cc";
}
.icon-sphere:before {
  content: "\e9cd";
}
.icon-earth:before {
  content: "\e9ce";
}
.icon-link:before {
  content: "\e9cf";
}
.icon-flag:before {
  content: "\e9d0";
}
.icon-attachment:before {
  content: "\e9d1";
}
.icon-eye:before {
  content: "\e9d2";
}
.icon-eye-plus:before {
  content: "\e9d3";
}
.icon-eye-minus:before {
  content: "\e9d4";
}
.icon-eye-blocked:before {
  content: "\e9d5";
}
.icon-bookmark:before {
  content: "\e9d6";
}
.icon-bookmarks:before {
  content: "\e9d7";
}
.icon-sun:before {
  content: "\e9d8";
}
.icon-contrast:before {
  content: "\e9d9";
}
.icon-brightness-contrast:before {
  content: "\e9da";
}
.icon-star-empty:before {
  content: "\e9db";
}
.icon-star-half:before {
  content: "\e9dc";
}
.icon-star-full:before {
  content: "\e9dd";
}
.icon-heart:before {
  content: "\e9de";
}
.icon-heart-broken:before {
  content: "\e9df";
}
.icon-man:before {
  content: "\e9e0";
}
.icon-woman:before {
  content: "\e9e1";
}
.icon-man-woman:before {
  content: "\e9e2";
}
.icon-happy:before {
  content: "\e9e3";
}
.icon-happy2:before {
  content: "\e9e4";
}
.icon-smile:before {
  content: "\e9e5";
}
.icon-smile2:before {
  content: "\e9e6";
}
.icon-tongue:before {
  content: "\e9e7";
}
.icon-tongue2:before {
  content: "\e9e8";
}
.icon-sad:before {
  content: "\e9e9";
}
.icon-sad2:before {
  content: "\e9ea";
}
.icon-wink:before {
  content: "\e9eb";
}
.icon-wink2:before {
  content: "\e9ec";
}
.icon-grin:before {
  content: "\e9ed";
}
.icon-grin2:before {
  content: "\e9ee";
}
.icon-cool:before {
  content: "\e9ef";
}
.icon-cool2:before {
  content: "\e9f0";
}
.icon-angry:before {
  content: "\e9f1";
}
.icon-angry2:before {
  content: "\e9f2";
}
.icon-evil:before {
  content: "\e9f3";
}
.icon-evil2:before {
  content: "\e9f4";
}
.icon-shocked:before {
  content: "\e9f5";
}
.icon-shocked2:before {
  content: "\e9f6";
}
.icon-baffled:before {
  content: "\e9f7";
}
.icon-baffled2:before {
  content: "\e9f8";
}
.icon-confused:before {
  content: "\e9f9";
}
.icon-confused2:before {
  content: "\e9fa";
}
.icon-neutral:before {
  content: "\e9fb";
}
.icon-neutral2:before {
  content: "\e9fc";
}
.icon-hipster:before {
  content: "\e9fd";
}
.icon-hipster2:before {
  content: "\e9fe";
}
.icon-wondering:before {
  content: "\e9ff";
}
.icon-wondering2:before {
  content: "\ea00";
}
.icon-sleepy:before {
  content: "\ea01";
}
.icon-sleepy2:before {
  content: "\ea02";
}
.icon-frustrated:before {
  content: "\ea03";
}
.icon-frustrated2:before {
  content: "\ea04";
}
.icon-crying:before {
  content: "\ea05";
}
.icon-crying2:before {
  content: "\ea06";
}
.icon-point-up:before {
  content: "\ea07";
}
.icon-point-right:before {
  content: "\ea08";
}
.icon-point-down:before {
  content: "\ea09";
}
.icon-point-left:before {
  content: "\ea0a";
}
.icon-warning:before {
  content: "\ea0b";
}
.icon-notification:before {
  content: "\ea0c";
}
.icon-question:before {
  content: "\ea0d";
}
.icon-plus:before {
  content: "\ea0e";
}
.icon-minus:before {
  content: "\ea0f";
}
.icon-info:before {
  content: "\ea10";
}
.icon-cancel-circle:before {
  content: "\ea11";
}
.icon-blocked:before {
  content: "\ea12";
}
.icon-cross:before {
  content: "\ea13";
}
.icon-checkmark:before {
  content: "\ea14";
}
.icon-checkmark2:before {
  content: "\ea15";
}
.icon-spell-check:before {
  content: "\ea16";
}
.icon-enter:before {
  content: "\ea17";
}
.icon-exit:before {
  content: "\ea18";
}
.icon-play2:before {
  content: "\ea19";
}
.icon-pause:before {
  content: "\ea1a";
}
.icon-stop:before {
  content: "\ea1b";
}
.icon-previous:before {
  content: "\ea1c";
}
.icon-next:before {
  content: "\ea1d";
}
.icon-backward:before {
  content: "\ea1e";
}
.icon-forward2:before {
  content: "\ea1f";
}
.icon-play3:before {
  content: "\ea20";
}
.icon-pause2:before {
  content: "\ea21";
}
.icon-stop2:before {
  content: "\ea22";
}
.icon-backward2:before {
  content: "\ea23";
}
.icon-forward3:before {
  content: "\ea24";
}
.icon-first:before {
  content: "\ea25";
}
.icon-last:before {
  content: "\ea26";
}
.icon-previous2:before {
  content: "\ea27";
}
.icon-next2:before {
  content: "\ea28";
}
.icon-eject:before {
  content: "\ea29";
}
.icon-volume-high:before {
  content: "\ea2a";
}
.icon-volume-medium:before {
  content: "\ea2b";
}
.icon-volume-low:before {
  content: "\ea2c";
}
.icon-volume-mute:before {
  content: "\ea2d";
}
.icon-volume-mute2:before {
  content: "\ea2e";
}
.icon-volume-increase:before {
  content: "\ea2f";
}
.icon-volume-decrease:before {
  content: "\ea30";
}
.icon-loop:before {
  content: "\ea31";
}
.icon-loop2:before {
  content: "\ea32";
}
.icon-infinite:before {
  content: "\ea33";
}
.icon-shuffle:before {
  content: "\ea34";
}
.icon-arrow-up-left:before {
  content: "\ea35";
}
.icon-arrow-up:before {
  content: "\ea36";
}
.icon-arrow-up-right:before {
  content: "\ea37";
}
.icon-arrow-right:before {
  content: "\ea38";
}
.icon-arrow-down-right:before {
  content: "\ea39";
}
.icon-arrow-down:before {
  content: "\ea3a";
}
.icon-arrow-down-left:before {
  content: "\ea3b";
}
.icon-arrow-left:before {
  content: "\ea3c";
}
.icon-arrow-up-left2:before {
  content: "\ea3d";
}
.icon-arrow-up2:before {
  content: "\ea3e";
}
.icon-arrow-up-right2:before {
  content: "\ea3f";
}
.icon-arrow-right2:before {
  content: "\ea40";
}
.icon-arrow-down-right2:before {
  content: "\ea41";
}
.icon-arrow-down2:before {
  content: "\ea42";
}
.icon-arrow-down-left2:before {
  content: "\ea43";
}
.icon-arrow-left2:before {
  content: "\ea44";
}
.icon-circle-up:before {
  content: "\ea45";
}
.icon-circle-right:before {
  content: "\ea46";
}
.icon-circle-down:before {
  content: "\ea47";
}
.icon-circle-left:before {
  content: "\ea48";
}
.icon-tab:before {
  content: "\ea49";
}
.icon-move-up:before {
  content: "\ea4a";
}
.icon-move-down:before {
  content: "\ea4b";
}
.icon-sort-alpha-asc:before {
  content: "\ea4c";
}
.icon-sort-alpha-desc:before {
  content: "\ea4d";
}
.icon-sort-numeric-asc:before {
  content: "\ea4e";
}
.icon-sort-numberic-desc:before {
  content: "\ea4f";
}
.icon-sort-amount-asc:before {
  content: "\ea50";
}
.icon-sort-amount-desc:before {
  content: "\ea51";
}
.icon-command:before {
  content: "\ea52";
}
.icon-shift:before {
  content: "\ea53";
}
.icon-ctrl:before {
  content: "\ea54";
}
.icon-opt:before {
  content: "\ea55";
}
.icon-checkbox-checked:before {
  content: "\ea56";
}
.icon-checkbox-unchecked:before {
  content: "\ea57";
}
.icon-radio-checked:before {
  content: "\ea58";
}
.icon-radio-checked2:before {
  content: "\ea59";
}
.icon-radio-unchecked:before {
  content: "\ea5a";
}
.icon-crop:before {
  content: "\ea5b";
}
.icon-make-group:before {
  content: "\ea5c";
}
.icon-ungroup:before {
  content: "\ea5d";
}
.icon-scissors:before {
  content: "\ea5e";
}
.icon-filter:before {
  content: "\ea5f";
}
.icon-font:before {
  content: "\ea60";
}
.icon-ligature:before {
  content: "\ea61";
}
.icon-ligature2:before {
  content: "\ea62";
}
.icon-text-height:before {
  content: "\ea63";
}
.icon-text-width:before {
  content: "\ea64";
}
.icon-font-size:before {
  content: "\ea65";
}
.icon-bold:before {
  content: "\ea66";
}
.icon-underline:before {
  content: "\ea67";
}
.icon-italic:before {
  content: "\ea68";
}
.icon-strikethrough:before {
  content: "\ea69";
}
.icon-omega:before {
  content: "\ea6a";
}
.icon-sigma:before {
  content: "\ea6b";
}
.icon-page-break:before {
  content: "\ea6c";
}
.icon-superscript:before {
  content: "\ea6d";
}
.icon-subscript:before {
  content: "\ea6e";
}
.icon-superscript2:before {
  content: "\ea6f";
}
.icon-subscript2:before {
  content: "\ea70";
}
.icon-text-color:before {
  content: "\ea71";
}
.icon-pagebreak:before {
  content: "\ea72";
}
.icon-clear-formatting:before {
  content: "\ea73";
}
.icon-table:before {
  content: "\ea74";
}
.icon-table2:before {
  content: "\ea75";
}
.icon-insert-template:before {
  content: "\ea76";
}
.icon-pilcrow:before {
  content: "\ea77";
}
.icon-ltr:before {
  content: "\ea78";
}
.icon-rtl:before {
  content: "\ea79";
}
.icon-section:before {
  content: "\ea7a";
}
.icon-paragraph-left:before {
  content: "\ea7b";
}
.icon-paragraph-center:before {
  content: "\ea7c";
}
.icon-paragraph-right:before {
  content: "\ea7d";
}
.icon-paragraph-justify:before {
  content: "\ea7e";
}
.icon-indent-increase:before {
  content: "\ea7f";
}
.icon-indent-decrease:before {
  content: "\ea80";
}
.icon-share:before {
  content: "\ea81";
}
.icon-new-tab:before {
  content: "\ea82";
}
.icon-embed:before {
  content: "\ea83";
}
.icon-embed2:before {
  content: "\ea84";
}
.icon-terminal:before {
  content: "\ea85";
}
.icon-share2:before {
  content: "\ea86";
}
.icon-mail:before {
  content: "\ea87";
}
.icon-mail2:before {
  content: "\ea88";
}
.icon-mail3:before {
  content: "\ea89";
}
.icon-mail4:before {
  content: "\ea8a";
}
.icon-amazon:before {
  content: "\ea8b";
}
.icon-google:before {
  content: "\ea8c";
}
.icon-google2:before {
  content: "\ea8d";
}
.icon-google3:before {
  content: "\ea8e";
}
.icon-google-plus:before {
  content: "\ea8f";
}
.icon-google-plus2:before {
  content: "\ea90";
}
.icon-google-plus3:before {
  content: "\ea91";
}
.icon-hangouts:before {
  content: "\ea92";
}
.icon-google-drive:before {
  content: "\ea93";
}
.icon-facebook:before {
  content: "\ea94";
}
.icon-facebook2:before {
  content: "\ea95";
}
.icon-instagram:before {
  content: "\ea96";
}
.icon-whatsapp:before {
  content: "\ea97";
}
.icon-spotify:before {
  content: "\ea98";
}
.icon-telegram:before {
  content: "\ea99";
}
.icon-twitter:before {
  content: "\ea9a";
}
.icon-vine:before {
  content: "\ea9b";
}
.icon-vk:before {
  content: "\ea9c";
}
.icon-renren:before {
  content: "\ea9d";
}
.icon-sina-weibo:before {
  content: "\ea9e";
}
.icon-rss:before {
  content: "\ea9f";
}
.icon-rss2:before {
  content: "\eaa0";
}
.icon-youtube:before {
  content: "\eaa1";
}
.icon-youtube2:before {
  content: "\eaa2";
}
.icon-twitch:before {
  content: "\eaa3";
}
.icon-vimeo:before {
  content: "\eaa4";
}
.icon-vimeo2:before {
  content: "\eaa5";
}
.icon-lanyrd:before {
  content: "\eaa6";
}
.icon-flickr:before {
  content: "\eaa7";
}
.icon-flickr2:before {
  content: "\eaa8";
}
.icon-flickr3:before {
  content: "\eaa9";
}
.icon-flickr4:before {
  content: "\eaaa";
}
.icon-dribbble:before {
  content: "\eaab";
}
.icon-behance:before {
  content: "\eaac";
}
.icon-behance2:before {
  content: "\eaad";
}
.icon-deviantart:before {
  content: "\eaae";
}
.icon-500px:before {
  content: "\eaaf";
}
.icon-steam:before {
  content: "\eab0";
}
.icon-steam2:before {
  content: "\eab1";
}
.icon-dropbox:before {
  content: "\eab2";
}
.icon-onedrive:before {
  content: "\eab3";
}
.icon-github:before {
  content: "\eab4";
}
.icon-npm:before {
  content: "\eab5";
}
.icon-basecamp:before {
  content: "\eab6";
}
.icon-trello:before {
  content: "\eab7";
}
.icon-wordpress:before {
  content: "\eab8";
}
.icon-joomla:before {
  content: "\eab9";
}
.icon-ello:before {
  content: "\eaba";
}
.icon-blogger:before {
  content: "\eabb";
}
.icon-blogger2:before {
  content: "\eabc";
}
.icon-tumblr:before {
  content: "\eabd";
}
.icon-tumblr2:before {
  content: "\eabe";
}
.icon-yahoo:before {
  content: "\eabf";
}
.icon-yahoo2:before {
  content: "\eac0";
}
.icon-tux:before {
  content: "\eac1";
}
.icon-appleinc:before {
  content: "\eac2";
}
.icon-finder:before {
  content: "\eac3";
}
.icon-android:before {
  content: "\eac4";
}
.icon-windows:before {
  content: "\eac5";
}
.icon-windows8:before {
  content: "\eac6";
}
.icon-soundcloud:before {
  content: "\eac7";
}
.icon-soundcloud2:before {
  content: "\eac8";
}
.icon-skype:before {
  content: "\eac9";
}
.icon-reddit:before {
  content: "\eaca";
}
.icon-hackernews:before {
  content: "\eacb";
}
.icon-wikipedia:before {
  content: "\eacc";
}
.icon-linkedin:before {
  content: "\eacd";
}
.icon-linkedin2:before {
  content: "\eace";
}
.icon-lastfm:before {
  content: "\eacf";
}
.icon-lastfm2:before {
  content: "\ead0";
}
.icon-delicious:before {
  content: "\ead1";
}
.icon-stumbleupon:before {
  content: "\ead2";
}
.icon-stumbleupon2:before {
  content: "\ead3";
}
.icon-stackoverflow:before {
  content: "\ead4";
}
.icon-pinterest:before {
  content: "\ead5";
}
.icon-pinterest2:before {
  content: "\ead6";
}
.icon-xing:before {
  content: "\ead7";
}
.icon-xing2:before {
  content: "\ead8";
}
.icon-flattr:before {
  content: "\ead9";
}
.icon-foursquare:before {
  content: "\eada";
}
.icon-yelp:before {
  content: "\eadb";
}
.icon-paypal:before {
  content: "\eadc";
}
.icon-chrome:before {
  content: "\eadd";
}
.icon-firefox:before {
  content: "\eade";
}
.icon-IE:before {
  content: "\eadf";
}
.icon-edge:before {
  content: "\eae0";
}
.icon-safari:before {
  content: "\eae1";
}
.icon-opera:before {
  content: "\eae2";
}
.icon-file-pdf:before {
  content: "\eae3";
}
.icon-file-openoffice:before {
  content: "\eae4";
}
.icon-file-word:before {
  content: "\eae5";
}
.icon-file-excel:before {
  content: "\eae6";
}
.icon-libreoffice:before {
  content: "\eae7";
}
.icon-html-five:before {
  content: "\eae8";
}
.icon-html-five2:before {
  content: "\eae9";
}
.icon-css3:before {
  content: "\eaea";
}
.icon-git:before {
  content: "\eaeb";
}
.icon-codepen:before {
  content: "\eaec";
}
.icon-svg:before {
  content: "\eaed";
}
.icon-IcoMoon:before {
  content: "\eaee";
}
