@media (max-width:1440px){
    .rt-wrapper {padding: 105px 30px 60px 95px;}
    .rt-recentjobsposts{
        .rt-themetableholder{
            .rt-themetable{
                thead{
                    tr{
                        th{
                            ul{
                                li{padding: 0 8px;}
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        td{ .rt-themedropdown{right: 0;}}
                    }
                }
            }
        }
    }
    .rt-jobstable{
        thead{
            tr{
                th{
                    ul{
                        li{padding: 0 10px;}
                    }
                }
            }
        }
        tbody{
            tr{
                td{
                    ul{
                        li{padding: 0 10px;}
                    }
                    /* p{
                        width: 130px;
                    } */
                }
            }
        }
    }
    .rt-themetable{
        tbody{
            tr{
                td{
                    p{width: 13em;}
                }
            }
        }
    }
}
@media (max-width:1366px){
    .rt-packageplan {
        .rt-description{
             p{
                 font-size: 14px;
                 line-height: 24px;
             }
        }
    }
    .rt-packageplan {
        .rt-radio{
            input[type=radio] + label{padding: 10px;}
        }
    }
}


@media (max-width:1280px){
    .rt-postajobbutton{min-height: 100px;}
    .rt-applicantsstates {width: calc(100% - 330px);}
    .rt-jobpostedrates {width: 300px;}
    .rt-newhirings {width: 330px;}
    .rt-resentjobsbox {width: calc(100% - 360px);}
    .rt-themetable{
        tbody{
            tr{
                td{
                    ul{
                        li{padding: 0 8px;}
                    }
                }
            }
        }
    }
    .rt-jobslistsarea {width: 100% !important;}
    .rt-jobpreviewarea {
        top: 0;
        z-index: 5;
        right: -500px;
        max-width: 350px;
        position: absolute;
    }
    .rt-applicationspage{
        .rt-jobpreviewarea{max-width: 420px;}
    }
    .show-jobdetail .rt-jobpreviewarea,
    .show-applicantdetail .rt-jobpreviewarea{right: 0;}
    .rt-jobname {
        font-size: 16px;
        line-height: 16px;
    }
    .rt-chatsidebar {width: 330px;}
    .rt-chatpanel {width: calc(100% - 360px);}
    .rt-btnjobcard{
        top: 20px;
        transform: none;
    }
    .show-candidatedetail{
		.rt-jobslistsarea{width: 100% !important;}
		.rt-jobpreviewarea{right: 0;}
	}
}
@media (max-width:1199px){
    .rt-formprofile{
        fieldset{
            padding: 0;
            .form-group{
                width: 100% !important;
                float: left !important;
            }
            .rt-addressinput{
                margin:  0 !important;
                float: left !important;
                width: 100% !important;
            }
        }
    }
    .rt-changeprofileimage {
        width: 100%;
        float: left;
        margin: 30px 0 0;
        position: static;
    }
    .rt-changeimage{
        label {
            float: none;
            margin: 0 auto;
            display: block;
            max-width: 180px;
            font-family: 'proxima_novabold';
        }
    }
    .rt-twocols {
        .form-group{
            margin: 0 15px 30px !important;
            width: calc(100% - 30px) !important;
        }
    }
    .rt-usermanagementtab {
        padding: 0;
        .rt-profilebuttons{
            width: 100%;
            float: left;
            margin: 30px 0 0;
            position: static;
        }
    }
    .rt-packageplans{
        ul{
            li{
                width: 100%;
                padding: 15px;
            }
        }
    }
}
@media (max-width:1080px){
    .AuthPage {
        .rt-logincontent {padding: 50px 50px;}
        .rt-logoarea{
            width: 200px;
            margin: 0 auto 30px;
        }
        .rt-signinnav{padding: 0 0 30px;}
        .rt-formsignin{
            fieldset{
                .form-group{margin: 0 0 25px;}
            }
        }
        .rt-btnalreadyhave{margin: -30px 0 25px;}
    }
    .SignupCompletionPage{
        .rt-logoarea {
            width: 200px;
            margin: 0 auto 30px;
        }
        .rt-logincontent{padding: 50px;}
    }
    .rt-applicantsstates{
        width: 100%;
        margin: 0 0 30px;
    }
    .rt-themebox{min-height: auto;}
    .rt-jobpostedrates{width: 100%;}
    .rt-jobpostedrates{
        .rt-userstates li{
            width: 50%;
            padding: 0 15px;
            text-align: left;
            &:first-child{padding-left: 0;}
            &:last-child{padding-right: 0;}
        }
        li + li{
            border-top: 0 !important;
            border-left: 1px solid #f1f1f5;
        }
    }
    .rt-newhirings{
        width: 100%;
        margin: 0 0 30px;
    }
    .rt-resentjobsbox{width: 100%;}
    .rt-jobuserimg {
        width: 55px;
        height: 55px;
        margin: 0 10px 0 0;
    }
    .rt-userjobinfo{width: 100%;}
    .rt-jobuserinfo h4 {
        font-size: 16px;
        line-height: 16px;
    }
    .rt-jobuserinfo span {
        font-size: 12px;
        line-height: 12px;
    }
    .rt-chatsidebar {width: 280px;}
    .rt-user {.rt-userimg {margin: 0 5px 0 0;}}
    .rt-chatpanel {width: calc(100% - 310px);}
    .rt-updatebtnholder{
        button {
            padding: 0 50px;
            min-width: auto;
        }
    }
    .rt-profiletab{padding: 0;}
    .rt-profilebuttons {
        width: 100%;
        float: left;
        position: static;
        margin: 30px 0 0;
    }
    .rt-createnewuser{padding: 0;}
    .rt-billingtab {padding: 0;}
}
@media (max-width:991px){
    .rt-loginimg{display: none;}
    .AuthPage {
        .rt-logincontent{
            min-height: auto;
            padding: 30px 15px;
        }
        .rt-loginarea{padding: 0;}
        .rt-signinnav{
            font-size: 16px;
            line-height: 16px;
        }
    }
    .SignupCompletionPage{
        .rt-loginarea{padding: 0;}
        .rt-logincontent{
            min-height: auto;
            padding: 30px 15px;
        }
    }
    .ForgotPasswordPage{
        .rt-logoarea {
            width: 200px;
            margin: 0 auto 30px;
        }
        .rt-logincontent{
            min-height: auto;
            padding: 30px 15px;
        }
        .rt-loginarea{padding: 0;}
    }
    .ForgotPasswordPage{
        .rt-loginarea{padding: 0;}
    }
    .rt-twocol{
        margin-bottom: 0;
        .form-group{
            margin: 0 15px 30px !important;
            width: calc(100% - 30px) !important;
        }
        .rt-twocoltwo{width: 100%;}
    }
    .rt-twocoltwo .form-group:last-child,
    .rt-twocoltwo .form-group:nth-last-child(2) {margin-bottom: 30px !important;}
    .rt-formcreatenewjob{
        .rt-formbtns{
            margin: 0;
            button{
                min-width: auto !important;
                padding: 0 25px !important;
            }
            button + button{margin: 0 0 0 8px !important;}
        }
    }
    .rt-chatsidebar{
        width: 100%;
        padding: 10px 5px 5px;
    }
    .rt-sidebarhead {.rt-formsearch {padding: 10px 0 0;}}
    .rt-chatusers{
        height: 180px;
        li{padding: 8px 5px;}
    }
    .rt-applicationspage{
        .rt-jobstable{tbody{tr{td{span {width: "100%";}}}}}}
        .rt-chatpanel {
            width: 100%;
            margin: 15px 0 0;
        }
}
@media (max-width:800px){
    .rt-themetable{
        tbody{
            tr{
                td{
                    h3,
                    time{
                        font-size: 12px;
                        line-height: 12px;
                    }
                    ul{
                        li{
                            span{
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
    .rt-statescontentholder{padding: 0;}
    .rt-userstates{
        li{
            width: 100%;
            padding: 10px 0;
        }
    }
    .rt-postajobbutton{min-height: 200px;}
}
@media (max-width:768px){
    .rt-wrapper {padding: 105px 15px 60px 80px;}
    .rt-themetableholder{
        .table{
            thead{display: none;}
            tbody{
                tr{
                    width: 100%;
                    float: left;
                    td {
                        width: 100%;
                        float: left;
                        display: block;
                        text-align: left;
                        position: relative;
                        padding: 12px 20px 12px 110px;
                        &:before{
                            top: 0;
                            left: 0;
                            width: 20%;
                            height: 100%;
                            display: flex;
                            font-size: 11px;
                            color: #44444f;
                            line-height: 13px;
                            position: absolute;
                            align-items: center;
                            content: attr(data-title);
                        }
                      }
                }
                tr + tr{border-top: 1px solid #ccc;}
            }
        }
    }
    .rt-jobstable{
        tbody{
            tr{
                td{
                    &:first-child{border-radius: 0;}
                    &:last-child{border-radius: 0;}
                    &:before{left: 10px !important;}
                    button{
                        &:before{display: none;}
                    }
                }
            }
        }
    }
    .rt-candidatespage .rt-jobstable {min-width: auto;}
    .rt-pagetitle h1 {
        font-size: 18px;
        line-height: 18px;
    }
    .at-jobareahead h3 {
        font-size: 14px;
        line-height: 14px;
        padding: 0 0 0 5px;
    }
    .rt-themenavtabs {
        font-size: 18px;
        line-height: 18px;
        li{padding: 0 15px;}
    }
    .rt-choosbilling{
        ul{
            li{width: 100%;}
            li + li{margin: 15px 0 0;}
        }
    }
}
@media (max-width:640px){
    .rt-wrapper{padding: 105px 15px 60px 15px;}
    .rt-btntogglesidebar{display: block;}
    .rt-sidebarholder{left: -100%;}
    .show-sidebar .rt-sidebarholder{
        left: 0;
        width: 250px;
    }
    .show-sidebar .rt-sidebarholder{
        .rt-navigation{
            ul{
                li{
                    span{right: -20px;}
                }
            }
        }
    }
    .rt-postajobbutton{
        border: 0;
        width: 100%;
        float: left;
        display: block;
        transform: none;
        min-height: auto;
        margin: 30px 0 0;
        position: static;
        padding: 25px 0 0;
        text-align: center;
        border-top: 1px solid #f1f1f5;
    }
    .rt-btnaction + .rt-btnaction {margin: 0 0 0 8px;}
    .rt-jobpostedrates{
        .rt-userstates{
            li{
                width: 100%;
                float: left;
                padding: 15px 0;
            }
            li + li{
                border-left: 0;
                border-top: 1px solid #f1f1f5 !important;
            }
        }
    }
    .rt-jobpreviewarea .rt-btnactions li {padding: 0 5px;}
}
@media (max-width:568px){
    .rt-formcreatenewjob{
        .rt-formbtns{
            button{
                width: 100% !important;
            }
            button + button{margin: 10px 0 0 !important;}
        }
    }
    .rt-themenavtabs {
        font-size: 14px;
        line-height: 14px;
        li{padding: 0 8px;}
    }
    .rt-notification ul li h5 {
        width: 15.5em;
        font-size: 12px;
        line-height: 25px;
    }
    .rt-updatebtnholder{
        button{
            margin: 0;
            width: 100%;
        }
        button + button{margin: 15px 0 0;}
    }
    .rt-selectresult{margin: 0;}
    .rt-btnpostajob {
        cursor: pointer;
        font-size: 12px;
        padding: 0 20px;
        line-height: 40px;
    }
    .rt-formtheme{
        fieldset{
            .form-group{
                input{font-size: 14px;}
            }
        }
    }
    .rt-headdropdown{
        width: 100%;
        float: left;
        margin: 15px 0 0;
    }
    .rt-applicantinfoarea {height: calc(100vh - 480px);}
    .rt-btnsholder{
        .rt-btnlogin{
            width: 100% !important;
            float: left !important;
        }
        .rt-btnlogin + .rt-btnlogin{margin: 10px 0 0;}
    }
    .rt-chathead{
        .rt-btnjobcard{
            float: left;
            margin: 15px 0 0;
            position: static !important;
        }
        .rt-jobuserinfo{
            width: 100%;
            float: left;
            margin: 15px 0 0;
        }
        .rt-jobuserinfo{
            ul{
                li{
                    width: 100%;
                    float: left;
                    &:before{display: none;}
                }
                li + li{padding: 10px 0 0;}
            }
        }
    }
}
@media (max-height:850px){
    .rt-chatpanel {height: 656px;}
    .rt-chatmessages{height: 300px;}
}
@media (max-height:640px){
    .rt-jobslistsarea{
        .rt-themetableholder {
            height: 250px;
        }
    }
    .rt-applicationspage{
        .rt-jobpreviewarea{height: 100%;}
        .rt-applicantpreviewarea{height: 90%;}
        .rt-applicantinfoarea{height: 240px;}
    }
    .rt-candidatespage{
        .rt-jobpreviewarea{height: 100%;}
        .rt-applicantpreviewarea{height: 285px;}
        .rt-applicantinfoarea{height: 270px !important;}
    }
    .rt-joblistpage{
        .rt-jobpreviewarea{height: 100%;}
        .rt-jobpreview {height: calc(100vh - 65px);}
    }
    .rt-chatpanel {height: 510px;}
    .rt-chatmessages{height: 300px;}
}