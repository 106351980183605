.SideNav {
}
.cursorPointer{
    cursor: pointer;
}
.userimg{
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50% !important;
    margin: 0;
}