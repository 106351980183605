.mobileAppWrapper{
    padding: 40px;
    text-align: center;
}

.dymeAppHeading{
    color:#2459FB;
    margin-top: 40px;
    font-weight: 600;
    font-size: 30px;
}

.appStoreWrapper{
    display: inline-block; overflow: hidden;
}
.appStoreLogo{
    /* border-radius: 13px; width: 250px; height: 83px; */
    margin-top: -25px;
    
}
.dymeWord{
    margin-top: 120px;
}
.appsLogo{
    width:30%;
}

.dymeIOSImg {
    width:calc(30% - 42px);
}

@media(max-width:768px){
    .appsLogo{
        width:50%;
    }
    .dymeIOSImg {
        width:calc(50% - 42px);
    }
    
}
@media(max-width:576px){
    .mobileAppWrapper{
        padding: 40px 20px;

    }
    .appsLogo{
        width:100%;
    }
    .dymeIOSImg{
        width:calc(100% - 42px);
    }
    .appStoreLogo{
        margin-top: -15px;

    }
}
