.login_button {
  background: #fff;
  border-radius: 40px;
  display: block;
  border: none;
  padding: 0 40px;
  margin-bottom: 22px;
  cursor: pointer;
  width: 100%;
  font-size: calc(.27548vw + 12.71074px);
  font-weight: bold;
  text-align: center;
  color: #000;
  span {
    display: inline-block;
    padding: 19px 0 15px 40px;
    text-transform: uppercase;
  }
  &.google {
    span{
      background:url(../images/google-logo.png) no-repeat left center/30px ; /* with class ModalCarrot ??*/
    }
  }
  &.facebook {
    span{
      background:url(../images/facebook.png) no-repeat left center/30px ; /* with class ModalCarrot ??*/
    }
  }
  &.apple {
    span{
      background:url(../images/apple.png) no-repeat left center/40px ; /* with class ModalCarrot ??*/
    }
  }
}

// .kep-login-facebook .fa {
//   font-size: 20px;
//   color: "#3A559B";
//   font-weight: bold;
//   text-align: center;
// }