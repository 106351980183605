/* =============================================
			Theme Reset Style					
============================================= */
:root {
  --DodgerBlue: #2574ff;
  --transition: all 300ms ease-in-out;
}
* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
$blue: #2a7fff;
/* *:after,
*:before { transition: var(--transition); } */
@font-face {
  font-family: "proxima_nova_altbold";
  src: url("../fonts/proxima_nova_alt_bold-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_alt_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_altlight";
  src: url("../fonts/proxima_nova_alt_light-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_alt_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_altthin";
  src: url("../fonts/proxima_nova_alt_thin-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_alt_thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_novablack";
  src: url("../fonts/proxima_nova_black-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_black-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_novabold";
  src: url("../fonts/proxima_nova_bold-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_novaextrabold";
  src: url("../fonts/proxima_nova_extrabold-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_novathin";
  src: url("../fonts/proxima_nova_thin-webfont.woff2") format("woff2"),
    url("../fonts/proxima_nova_thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_novaregular";
  src: url("../fonts/proximanova-regular-webfont.woff2") format("woff2"),
    url("../fonts/proximanova-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  color: #666;
  background: #fff;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #000;
  margin: 0 0 8px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  text-transform: none;
  font-family: "Muli", Arial, Helvetica, sans-serif;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #0f0f0f;
}
p a {
  transition: var(--transition);
}
p a,
p a:hover {
  text-decoration: underline;
}
p {
  margin: 0 0 20px;
  line-height: 20px;
  letter-spacing: normal;
}
a {
  text-decoration: none;
  transition: var(--transition);
}
a:hover,
a:focus,
a:active {
  outline: none;
  text-decoration: none;
}
ul {
  margin: 0;
  list-style: none;
  margin-bottom: 0 !important;
}
ul li {
  list-style-type: none;
}

figure {
  margin-bottom: 6px;
  position: relative;
}
img {
  height: auto;
  max-width: 100%;
}
strong {
  color: #414141;
}
iframe {
  width: 100%;
  float: left;
  border: none;
}
button {
  border: none;
  outline: none;
  background: none;
}
button:focus {
  outline: none !important;
}
textarea,
select,
.rt-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  border: 0;
  // color: #666;
  // outline: none;
  // height: 60px;
  // font-size: 14px;
  // box-shadow: none;
  // font-weight: 500;
  // line-height: 18px;
  // padding: 10px 40px;
  // border-radius: 50px;
  // display: inline-block;
  // vertical-align: middle;
  // -webkit-box-shadow: none;
  // -webkit-box-sizing: border-box;
  // box-sizing: border-box;
  // background: rgba(63, 87, 123, 0.05);
}
// textarea.form-control {
// 	resize: none;
// 	height: 210px;
// }
.form-control::-webkit-input-placeholder {
  color: rgb(210, 205, 205);
}
.form-control:-moz-placeholder {
  color: rgb(210, 205, 205);
}
.form-control::-moz-placeholder {
  color: rgb(210, 205, 205);
}
.form-control:-ms-input-placeholder {
  color: rgb(210, 205, 205);
}
.form-control::-ms-input-placeholder {
  color: rgb(210, 205, 205);
}
input:focus,
.select select:focus,
.form-control:focus {
  color: #666;
  border-color: #666;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: rgba(63, 87, 123, 0.05);
}
label {
  color: #666;
  display: block;
  font-weight: 400;
  margin-bottom: 10px;
}
form p {
  float: left;
  position: relative;
  // width: 100%;
}
form p span i {
  top: 13px;
  left: 16px;
  color: #474747;
  position: absolute;
}
option {
  padding: 5px 15px;
}
li {
  line-height: 1.3;
}
ul ul {
  margin-left: 20px;
}
ol {
  float: none;
  list-style: decimal;
  padding-left: 15px;
}
ol ol {
  margin-left: 20px;
}
ol li {
  list-style: decimal;
  width: 100%;
}
table {
  margin: 0 !important;
  background-color: transparent;
  max-width: 100%;
}
th {
  text-align: left;
}
table {
  width: 100%;
  margin-bottom: 15px;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  // border-top: 1px solid #dbdbdb;
  // border: 1px solid #dbdbdb;
  line-height: 2.5;
  padding-left: 3px;
  text-align: center;
  vertical-align: top;
}
table thead tr th {
  // border-top: 1px solid #dbdbdb;
  text-align: center;
  text-transform: capitalize;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #dbdbdb;
}
table > thead > tr > th {
  border-bottom: 2px solid #dbdbdb;
  vertical-align: bottom;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0;
}
table > tbody + tbody {
  border-top: 2px solid #dbdbdb;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 6px 10px;
}
p ins {
  color: #666;
}
dl dd {
  margin-left: 20px;
}
address {
  font-style: italic;
}
/* =============================================
			Theme Custom Select					
============================================= */
.rt-select {
  color: #666;
  float: left;
  width: 100%;
  position: relative;
  text-transform: uppercase;
}
.rt-select:after {
  top: 0;
  z-index: 2;
  right: 15px;
  color: #666;
  display: block;
  content: "\eaf5";
  line-height: 40px;
  position: absolute;
  text-align: center;
  font-size: inherit;
  pointer-events: none;
  font-family: "icomoon";
}
.rt-select select {
  z-index: 1;
  width: 100%;
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.rt-select select option {
  color: #666;
}
/* =============================================
		Theme Custom CheckBox Radio				
============================================= */
.rt-radio,
.rt-radio label,
.rt-checkbox,
.rt-checkbox label {
  margin: 0;
  width: 100%;
  float: left;
  position: relative;
}
.rt-radio label,
.rt-checkbox label {
  cursor: pointer;
  display: block;
  padding: 0 0 0 20px;
}
.rt-radio input[type="radio"],
.rt-checkbox input[type="checkbox"] {
  display: none;
}
.rt-radio input[type="radio"] + label:before,
.rt-checkbox input[type="checkbox"] + label:before {
  top: 4px;
  left: 0;
  color: #373542;
  font-size: 14px;
  line-height: 14px;
  content: "\f096";
  position: absolute;
  font-family: "FontAwesome";
}
.rt-radio input[type="radio"] + label:before {
  content: "";
}
.rt-radio input[type="radio"]:checked + label:before,
.rt-checkbox input[type="checkbox"]:checked + label:before {
  content: "\f046";
}
/* =============================================
			Theme Global Elements				
============================================= */
.rt-btn {
  color: #fff;
  padding: 0 12px;
  min-width: 150px;
  background: #000;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  font: 700 12px/30px "Muli", Arial, Helvetica, sans-serif;
}
.rt-btn:hover,
.rt-btn:focus {
  color: #fff;
}
.rt-btnwithicon i,
.rt-btnwithicon span {
  display: inline-block;
  vertical-align: middle;
}
.rt-btnwithicon i {
  margin: 0 10px 0 0;
}
.rt-btnwithtext span {
  float: left;
}
.rt-btnwithtext em {
  float: right;
  font-style: normal;
}
.rt-description {
  width: 100%;
  float: left;
}
.rt-formtheme {
  width: 100%;
  float: left;
  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    position: relative;
    .form-group {
      width: 100%;
      float: left;
      margin: 0 0 50px;
      text-align: left;
      position: relative;
      background: #fff;
      padding: 10px 20px;
      border-radius: 10px;
      i {
        top: 50%;
        z-index: 2;
        right: 20px;
        color: #ccc;
        font-size: 20px;
        line-height: 20px;
        position: absolute;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      label {
        width: 100%;
        float: left;
        color: #505050;
        font-size: 14px;
        line-height: 14px;
      }
      input {
        padding: 0;
        width: 100%;
        height: auto;
        color: #000;
        font-size: 24px;
        background: none;
        border-radius: 0;
        font-family: "proxima_novaregular";
      }
    }
  }
}
.rt-inputiconholder {
  width: 100%;
  float: left;
  position: relative;
}
/* =============================================
			Pages Styling
============================================= */
.rt-MainLayout {
  overflow: hidden;
}
/* =============================================
			Login Signup Styling
============================================= */
.auth_page_wrapper {
  background: #f4f6fa;
}
.referal_page {
  .referal_header {
    background: url(../images/dyme-blue-bg.png) no-repeat 0 0 / cover
      transparent;
    padding-top: 50px;
    padding-bottom: 120px;
    text-align: center;
    img {
      width: 180px;
    }
    .greating_text {
      margin-top: 20px;
      color: #fff;
      span {
        display: block;
      }
    }
  }
  #form_wrapper {
    max-width: 400px;
    margin: -120px auto 0;
    background: #fff;
    padding-top: 30px;
    border-radius: 10px 10px 0 0;
    @media (max-width: 600px) {
      max-width: 90%;
    }
    .blue_button,
    .yellow_btn {
      background: #ffa739;
      padding: 10px 40px;
      color: #fff;
      border-radius: 25px;
      border: none;
      display: block;
      font-size: 20px;
      min-width: 230px;
      margin: 0 auto;
      .spinner-grow-sm {
        position: relative;
        top: -1px;
        left: -10px;
        width: 1.4rem;
        height: 1.5rem;
      }
    }
    .blue_button {
      background: $blue;
      border-radius: 7px;
      width: 100%;
      font-size: 16px;
    }
    .rt-btnforgotpass {
      margin-top: 20px;
      text-align: center;
    }
    .signup_label {
      p {
        margin-bottom: 8px;
        font-size: 22px;
        text-align: center;
      }
    }
    .form-group {
      margin-bottom: 10px;
      padding: 10px;
    }
    .pasword_fireld_wrapper {
      position: relative;
      i {
        color: gray;
      }
    }
    .password_wrapper {
      position: relative;
      .show_hide_password {
        top: 23px;
        color: gray;
        cursor: pointer;
      }
    }
    .password_field {
      background: url(../images/password.png) no-repeat 6px center/18px #ecf3ff !important;
      padding: 12px 20px 12px 30px;
      background-size: 20px;
      border-radius: 7px;
      font-size: 18px;
    }
    .email_field {
      background: url(../images/email.png) no-repeat 6px center/18px #ecf3ff !important;
      padding: 12px 20px 12px 30px;
      background-size: 20px;
      border-radius: 7px;
      font-size: 18px;
    }
    .ender_code_button {
      position: relative;
      display: inline-block;
      background: #ffa739;
      padding: 3px 16px;
      color: #fff;
      border-radius: 25px;
      border: none;
      font-size: 14px;
      margin: 13px auto 0;
      float: right;
      i {
        position: relative;
        top: 0;
        left: 0;
        color: gray;
        transform: initial;
      }
    }
    .PhoneInputInput {
      font-size: 18px;
    }
    .username_field {
      background: url(../images/username.png) no-repeat 6px center/18px #ecf3ff !important;
      padding: 12px 20px 12px 30px;
      background-size: 20px;
      border-radius: 7px;
      font-size: 18px;
    }
    .PhoneInput {
      background: #ecf3ff !important;
      padding: 12px 20px 12px 12px;
      border-radius: 7px;
      font-size: 18px;
    }
    .job_descriptions {
      margin: 50px 0 50px;
      li {
        background: url(../images/list-dot.png) no-repeat 0 7px transparent;
        margin-bottom: 30px;
        padding-left: 18px;
        background-size: 8px;
      }
    }
    .text-danger {
      padding: 6px 3px 0px;
      display: block;
    }
  }
  &.Auth_Page {
    &.Auth_Page_v2 {
      background: url(../images/dyme-blue-bg-2.png);
      .rt-btnforgotpass {
        font-size: 22px;
        color: #fff;
        line-height: 1.5;
      }
      .auth_footer {
        color: #fff;
        a {
          color: #fff;
        }
      }
      .greating_text {
        margin-top: 20px;
        color: #fff;
        span {
          display: block;
        }
      }
    }
    .login_form_section2 {
      max-width: 420px;
      margin: 100px auto;
      #form_wrapper {
        margin-top: 16px;
        box-shadow: none;
        background: none;
        margin-top: 0;
        .form-group {
          background: none;
          i {
            top: 40px !important;
          }
          padding: 0;
          .text_field {
            color: #9aabc4 !important;
            background-color: #fff !important;

            padding: 26px 20px 26px 30px;
            margin-bottom: 7px;
            border-radius: 9px;
            &.email_field {
              background-image: url(../images/email.png) !important;
              background-size: 20px !important;
            }
          }
          .yellow_button {
            background: #ff9c32;
            color: #fff;
            font-size: 22px;
            border-radius: 35px;
            padding-top: 18px;
            padding-bottom: 18px;
            font-weight: bold;
            max-width: 300px;
            margin-top: 14px;
          }
        }
      }
    }
    .login_action_wrapper {
      .logo {
        width: 120px;
        margin-bottom: 30px;
      }
      .logo_text {
        font-size: 22px;
        color: #fff;
        line-height: 1.5;
      }
      .how_it_work {
        color: #fff;
        text-decoration: underline;
        display: inline-block;
        cursor: pointer;
        margin: 0;
        font-size: 20px;
        margin: 13px 0;
      }
      .or_text {
        font-size: 18px;
        margin-top: 20px;
        span {
          display: inline-block;
          color: #fff;
          position: relative;
          &:after {
            content: "";
            width: 108px;
            height: 1px;
            background: #fff;
            display: block;
            position: absolute;
            right: -120px;
            top: 10px;
          }
          &:before {
            content: "";
            width: 108px;
            height: 1px;
            background: #fff;
            display: block;
            position: absolute;
            left: -120px;
            top: 10px;
          }
        }
      }
    }
    .login_form_section {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .register_form_wrapper {
      position: relative;
      background: url(../images/lining-circle.png) no-repeat 30px 20px/190px
        transparent;
      z-index: 1;
      &:after {
        display: block;
        content: "";
        width: 200px;
        height: 200px;
        position: absolute;
        z-index: -1;
        background: url(../images/lining-circle.png) no-repeat 0 0/200px
          transparent;
        bottom: -30px;
        right: 30px;
      }
      .text-danger {
        color: #fff !important;
        padding: 9px 3px 0px;
        display: block;
      }
    }
    #form_wrapper {
      margin: 40px auto;
      overflow: hidden;
      padding: 20px 10px;
      box-shadow: 0 0 4px 0px lightgray;
      &.register_form {
        padding: 0;
        .blue_bg {
          overflow: hidden;
          background: $blue;
          padding: 20px;
          margin-bottom: 40px;
          .form-group {
            padding: 0;
            background: none;
          }
          .signup_title {
            color: #fff;
            text-align: center;
            margin: 10px ​0 20p;
          }
        }
        .blue_button {
          max-width: 300px;
          margin: 0 auto;
        }
        .rt-btnalreadyhave {
          margin-top: 20px;
          text-align: center;
          padding: 10px;
        }
      }
    }
    .rt-btnforgotpass {
      text-align: center;
      margin: 40px 0;
    }
    .points {
      margin: 50px 0 50px;
      li {
        background: url(../images/list-dot.png) no-repeat 0 7px transparent;
        margin-bottom: 30px;
        padding-left: 18px;
        background-size: 8px;
        color: white;
      }
    }
  }
}
.AuthPage {
  .rt-loginpage {
    width: 100%;
    float: left;
  }
  .rt-loginimg {
    top: 0;
    right: 0;
    margin: 0;
    width: 48%;
    height: 100vh;
    position: fixed;
    img {
      width: 100%;
      height: 100%;
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .rt-loginarea {
    width: 100%;
    float: left;
    padding: 0 48% 0 0;
  }
  .rt-logincontent {
    width: 100%;
    height: 100vh;
    overflow: auto;
    min-height: 768px;
    position: relative;
    padding: 50px 80px;
    text-align: center;
    background: #f2f5f9;
    text-align: center;
    /* align-items: center;
    display: flex;
    justify-content: center;
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
	flex-direction: column; */
  }
  .rt-logincontent::-webkit-scrollbar {
    display: none;
  }
  .rt-logoarea {
    width: 300px;
    overflow: hidden;
    margin: 0 auto 100px;
    .rt-logo {
      width: 100%;
      float: left;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .rt-loginsignupholder {
    width: 100%;
    float: left;
  }
  .rt-btnforgotpass {
    z-index: 3;
    width: 100%;
    float: left;
    text-align: right;
    position: relative;
    margin: -30px 0 50px;
    a {
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: middle;
      color: var(--DodgerBlue);
      font-family: "proxima_novaregular";
    }
  }
  .rt-signinnav {
    width: 100%;
    float: left;
    font-size: 24px;
    list-style: none;
    padding: 0 0 50px;
    line-height: 27px;
    font-family: "proxima_nova_altbold";
    li {
      float: left;
      padding: 0 25px;
      line-height: inherit;
      list-style-type: none;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      button {
        color: #ccc;
        display: block;
        cursor: pointer;
        background: none;
        padding: 0 0 15px;
        font-size: inherit;
        position: relative;
        line-height: inherit;
        line-height: 16px !important;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        &:before {
          left: 0;
          width: 0;
          bottom: 0;
          content: "";
          height: 4px;
          position: absolute;
          border-radius: 25px;
          background: var(--DodgerBlue);
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -ms-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }
        &:hover {
          color: #171725;
          font-family: "proxima_nova_altbold";
          &:before {
            width: 100%;
          }
        }
      }
    }
    .rt-active {
      button {
        color: #171725;
        font-family: "proxima_nova_altbold";
        &:before {
          width: 100%;
        }
      }
    }
    .rt-inputiconholder {
      width: 100%;
      float: left;
      position: relative;
    }
  }
  .rt-btnalreadyhave {
    width: 100%;
    float: left;
    text-align: right;
    position: relative;
    margin: -30px 0 50px;
    a,
    span {
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: middle;
      font-family: "proxima_novaregular";
    }
    a {
      color: var(--DodgerBlue);
    }
    span {
      color: #000;
      margin: 0 0 0 3px;
    }
  }
}
.rt-btnlogin {
  width: 100% !important;
  float: left !important;
  height: 70px !important;
  color: #fff !important;
  font-size: 24px !important;
  cursor: pointer !important;
  padding: 0 22px !important;
  line-height: 70px !important;
  position: relative !important;
  border-radius: 10px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  background: var(--DodgerBlue) !important;
  -webkit-box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.5);
  box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.5);
  i {
    top: 0;
    left: 22px;
    font-size: 40px;
    line-height: 70px;
    position: absolute;
    border-radius: 50%;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  i.spinner-grow {
    top: 19px;
    width: 30px;
    height: 30px;
  }
  span {
    top: 0;
    right: 22px;
    color: #fff;
    position: absolute;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
}
/* =============================================
			Forgot Styling
============================================= */
.ForgotPasswordPage {
  .rt-loginpage {
    width: 100%;
    float: left;
  }
  .rt-loginimg {
    top: 0;
    right: 0;
    margin: 0;
    width: 48%;
    height: 100vh;
    position: fixed;
    img {
      width: 100%;
      height: 100%;
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .rt-loginarea {
    width: 100%;
    float: left;
    padding: 0 48% 0 0;
  }
  .rt-logincontent {
    width: 100%;
    padding: 80px;
    height: 100vh;
    overflow: auto;
    /* min-height: 768px; */
    position: relative;
    text-align: center;
    background: #f2f5f9;
    text-align: center;
    /* align-items: center;
        display: flex;
        justify-content: center;
        justify-content: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        flex-direction: column; */
  }
  .rt-logoarea {
    width: 300px;
    margin: 0 auto 100px;
    .rt-logo {
      width: 100%;
      float: left;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .rt-formtextarea {
    width: 100%;
    float: left;
    text-align: left;
    padding: 0 0 55px;
    .rt-description {
      p {
        margin: 0;
        font-size: 18px;
        color: #696974;
        line-height: 20px;
      }
      p + p {
        margin: 5px 0 0;
      }
    }
  }
  .rt-forheading {
    width: 100%;
    float: left;
    padding: 0 0 55px;
    h3 {
      margin: 0;
      float: left;
      font-size: 24px;
      line-height: 24px;
      padding: 0 0 15px;
      position: relative;
      font-family: "proxima_nova_altbold";
      &:before {
        left: 0;
        bottom: 0;
        content: "";
        width: 75px;
        height: 4px;
        position: absolute;
        border-radius: 30px;
        background: var(--DodgerBlue);
      }
    }
  }
  .rt-loginsignupholder {
    width: 100%;
    float: left;
  }
  .rt-btnforgotpass {
    z-index: 3;
    width: 100%;
    float: left;
    text-align: right;
    position: relative;
    margin: -30px 0 50px;
    a {
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: middle;
      color: var(--DodgerBlue);
      font-family: "proxima_novaregular";
    }
  }
  .rt-signinnav {
    width: 100%;
    float: left;
    font-size: 24px;
    list-style: none;
    padding: 0 0 50px;
    line-height: 27px;
    font-family: "proxima_nova_altbold";
    li {
      float: left;
      padding: 0 25px;
      line-height: inherit;
      list-style-type: none;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      button {
        color: #ccc;
        display: block;
        cursor: pointer;
        background: none;
        padding: 0 0 15px;
        font-size: inherit;
        position: relative;
        line-height: inherit;
        line-height: 16px !important;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        &:before {
          left: 0;
          width: 0;
          bottom: 0;
          content: "";
          height: 4px;
          position: absolute;
          border-radius: 25px;
          background: var(--DodgerBlue);
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -ms-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }
        &:hover {
          color: #171725;
          font-family: "proxima_nova_altbold";
          &:before {
            width: 100%;
          }
        }
      }
    }
    .rt-active {
      button {
        color: #171725;
        font-family: "proxima_nova_altbold";
        &:before {
          width: 100%;
        }
      }
    }
    .rt-inputiconholder {
      width: 100%;
      float: left;
      position: relative;
    }
  }
  .rt-btnalreadyhave {
    width: 100%;
    float: left;
    text-align: right;
    position: relative;
    margin: -30px 0 50px;
    a,
    span {
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: middle;
      font-family: "proxima_novaregular";
    }
    a {
      color: var(--DodgerBlue);
    }
    span {
      color: #000;
      margin: 0 0 0 3px;
    }
  }
}
/* =============================================
			Verifivation Styling
============================================= */
.SignupCompletionPage {
  .rt-loginpage {
    width: 100%;
    float: left;
  }
  .rt-loginimg {
    top: 0;
    right: 0;
    margin: 0;
    width: 48%;
    height: 100vh;
    position: fixed;
    img {
      width: 100%;
      height: 100%;
      display: block;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .rt-loginarea {
    width: 100%;
    float: left;
    padding: 0 48% 0 0;
  }
  .rt-logincontent {
    width: 100%;
    height: 100vh;
    overflow: auto;
    /* min-height: 768px; */
    position: relative;
    padding: 50px 80px;
    text-align: center;
    background: #f2f5f9;
    text-align: center;
    /* align-items: center;
		display: flex;
		justify-content: center;
		justify-content: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		flex-direction: column; */
  }
  .rt-logoarea {
    width: 300px;
    overflow: hidden;
    margin: 0 auto 100px;
    .rt-logo {
      width: 100%;
      float: left;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
  .rt-verificationarea {
    .rt-loginsignupholder {
      width: 100%;
      float: left;
      text-align: left;
    }
    .rt-formtextarea {
      width: 100%;
      float: left;
      .rt-forheading {
        width: 100%;
        float: left;
        padding: 0 0 55px;
        h4 {
          color: #000;
          font-size: 24px;
          margin: 0 0 15px;
          line-height: 24px;
          font-family: "proxima_novaregular";
        }
        p {
          margin: 0;
          color: #000;
          font-size: 18px;
          line-height: 18px;
          font-family: "proxima_novaregular";
        }
      }
    }
    .rt-description {
      padding: 0 0 50px;
      p {
        margin: 0;
        font-size: 18px;
        color: #696974;
        line-height: 24px;
        font-family: "proxima_novaregular";
        span {
          display: block;
          em {
            font-style: normal;
            color: var(--DodgerBlue);
          }
        }
      }
    }
  }
  .rt-signinnav {
    width: 100%;
    float: left;
    font-size: 24px;
    list-style: none;
    padding: 0 0 50px;
    line-height: 27px;
    font-family: "proxima_nova_altbold";
    li {
      float: left;
      padding: 0 25px;
      line-height: inherit;
      list-style-type: none;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      button {
        color: #ccc;
        display: block;
        cursor: pointer;
        background: none;
        padding: 0 0 15px;
        font-size: inherit;
        position: relative;
        line-height: inherit;
        line-height: 16px !important;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
        &:before {
          left: 0;
          width: 0;
          bottom: 0;
          content: "";
          height: 4px;
          position: absolute;
          border-radius: 25px;
          background: var(--DodgerBlue);
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -ms-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }
        &:hover {
          color: #171725;
          font-family: "proxima_nova_altbold";
          &:before {
            width: 100%;
          }
        }
      }
    }
    .rt-active {
      button {
        color: #171725;
        font-family: "proxima_nova_altbold";
        &:before {
          width: 100%;
        }
      }
    }
    .rt-inputiconholder {
      width: 100%;
      float: left;
      position: relative;
    }
  }
  .rt-btnalreadyhave {
    width: 100%;
    float: left;
    text-align: right;
    position: relative;
    margin: -30px 0 50px;
    a,
    span {
      font-size: 16px;
      line-height: 16px;
      display: inline-block;
      vertical-align: middle;
      font-family: "proxima_novaregular";
    }
    a {
      color: var(--DodgerBlue);
    }
    span {
      color: #000;
      margin: 0 0 0 3px;
    }
  }
}
/* =============================================
			Header Styling
============================================= */
.rt-header {
  top: 0;
  left: 0;
  width: 100%;
  float: left;
  padding: 12px;
  z-index: 1000;
  position: fixed;
  background: #fff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e4e4e4;
}
.rt-logobuttonholder {
  float: left;
}
.rt-headerlogo {
  width: 80px;
  float: left;
  a,
  img {
    display: block;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.rt-btntogglesidebar {
  color: #ccc;
  display: none;
  font-size: 21px;
  cursor: pointer;
  padding: 0 0 0 15px;
}
.rt-usernotification {
  margin: 0;
  float: right;
  list-style: none;
  li {
    float: left;
    padding: 0 10px;
    list-style-type: none;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    a {
      // margin: 4px 0;
      display: block;
      font-size: 20px;
      color: #92929d;
      line-height: 20px;
      position: relative;
      i {
      }
    }
  }
}
.rt-notidot {
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  border-radius: 50%;
  background: #fc5a5a;
  border: 2px solid #fff;
}
.rt-userimg {
  margin: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #92929d;
  border: 1px #92929d;
  a {
    margin: 0;
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.rt-sidebarholder {
  left: 0;
  top: 71px;
  width: 65px;
  height: 100vh;
  position: fixed;
  background: #fff;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  &:hover {
    width: 250px;
  }
}
.rt-searchholder {
  width: 100%;
  float: left;
  overflow: hidden;
  padding: 24px 20px;
  position: relative;
  &:before {
    left: 50%;
    bottom: 0;
    content: "";
    height: 1px;
    width: 210px;
    position: absolute;
    background: #f1f1f5;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  a {
    float: left;
    font-size: 20px;
    color: #92929d;
    line-height: 20px;
    margin: 0 20px 0 0;
  }
}
.rt-sidebarsearch {
  float: left;
  width: calc(100% - 40px);
  fieldset {
    .form-group {
      margin: 0;
      input {
        padding: 0;
        color: #000;
        height: auto;
        background: none;
      }
    }
  }
}
.rt-navigation {
  width: 100%;
  float: left;
  overflow: auto;
  padding: 10px 0 0;
  height: calc(100vh - 140px);
  ::-webkit-scrollbar {
    display: none;
  }
  ul {
    margin: 0;
    width: 100%;
    float: left;
    font-size: 22px;
    list-style: none;
    line-height: 50px;
    li {
      width: 100%;
      float: left;
      line-height: inherit;
      list-style-type: none;
      a,
      button {
        display: block;
        padding: 0 20px;
        text-align: left;
        color: #92929d;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        &:hover {
          text-decoration: none;
          color: var(--DodgerBlue);
        }
        &:after {
          left: 0;
          top: 50%;
          width: 0;
          content: "";
          height: 30px;
          position: absolute;
          border-radius: 0 30px 30px 0;
          background: var(--DodgerBlue);
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -ms-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }
        &:hover {
          &:after {
            width: 4px;
          }
        }
        i,
        span {
          display: inline-block;
          vertical-align: middle;
        }
        span {
          top: 50%;
          color: #000;
          right: -250px;
          font-size: 14px;
          line-height: 14px;
          position: relative;
          -webkit-transition: all 300ms ease-in-out;
          -moz-transition: all 300ms ease-in-out;
          -ms-transition: all 300ms ease-in-out;
          -o-transition: all 300ms ease-in-out;
          transition: all 300ms ease-in-out;
        }
      }
      .rt-btnlogout {
        color: #fc5a5a;
        &:hover {
          color: #fc5a5a;
        }
        &:after {
          background: #fc5a5a;
        }
      }
    }
    .rt-active {
      a {
        color: var(--DodgerBlue);
        &:after {
          width: 4px;
        }
      }
    }
  }
}
.rt-navigation::-webkit-scrollbar {
  display: none;
}
.rt-sidebarholder {
  &:hover {
    .rt-navigation {
      ul {
        li {
          a {
            span {
              right: -20px;
            }
          }
        }
      }
    }
  }
}
/* =============================================
		Dashboard & Inner Pages Styling
============================================= */
.rt-wrapper {
  width: 100%;
  float: left;
  background: #f2f5f9;
  padding: 105px 60px 60px 125px;
}
.rt-dashboard {
  width: 100%;
  float: left;
}
.rt-pagetitle {
  width: 100%;
  float: left;
  padding: 0 0 35px;
  h1 {
    margin: 0;
    font-size: 24px;
    line-height: 24px;
    font-family: "proxima_novabold";
  }
}
/* =============================================
		Application States Styling
============================================= */
.rt-applicationstates {
  width: 100%;
  float: left;
}
.rt-themebox {
  width: 100%;
  float: left;
  padding: 25px;
  min-height: 260px;
  background: #fff;
  border-radius: 20px;
}
.rt-themeboxhead {
  width: 100%;
  float: left;
  padding: 0 0 30px;
  h2 {
    margin: 0;
    color: #000;
    font-size: 16px;
    line-height: 18px;
    font-family: "proxima_novabold";
  }
}
.rt-applicantsstates {
  width: calc(100% - 415px);
}
.show-applicantdetail {
  .rt-jobslistsarea {
    width: calc(100% - 450px);
  }
  .rt-jobpreviewarea {
    display: block;
  }
}
.rt-statescontentholder {
  width: 100%;
  float: left;
  position: relative;
  padding: 0 170px 0 0;
}
.rt-applicantsstates {
  .rt-themeboxhead {
    padding: 0 0 15px;
  }
}
.rt-presentyear {
  width: 100%;
  float: left;
  font-size: 14px;
  color: #696974;
  line-height: 16px;
  font-family: "proxima_novaregular";
}
.rt-postajobbutton {
  top: 50%;
  right: 0;
  width: 170px;
  text-align: right;
  min-height: 200px;
  position: absolute;
  border-left: 1px solid #f1f1f5;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-align-content: center;
  -ms-flex-line-pack: flex-end;
  align-content: flex-end;
}
.rt-btnpostajob {
  cursor: pointer;
  font-size: 15px;
  padding: 0 30px;
  line-height: 50px;
  border-radius: 10px;
  display: inline-block;
  background: #3dd598;
  vertical-align: middle;
  color: #fff !important;
  font-family: "proxima_novabold";
  text-decoration: none !important;
}
.rt-userstates {
  width: 100%;
  float: left;
  list-style: none;
  padding: 50px 0 0;
  li {
    float: left;
    width: 33.33%;
    list-style-type: none;
  }
}
.rt-states {
  width: 100%;
  float: left;
}
.rt-statescontent,
.rt-progressholder {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}
.rt-progressholder {
  padding: 0 15px 0 0;
}
.circle-wrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgba(0, 98, 255, 0.1);
}
.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 70px;
  height: 70px;
  position: absolute;
  border-radius: 50%;
}
.circle-wrap .circle .mask {
  clip: rect(70px, 0px, 0px, 70px);
}
.circle-wrap .circle .mask .fill {
  clip: rect(20px, 57px, 80px, 0);
  background-color: #9e00b1;
}
.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(126deg);
}
.circle-wrap .inside-circle {
  width: 60px;
  height: 60px;
  z-index: 100;
  color: #000;
  margin-top: 5px;
  font-size: 18px;
  margin-left: 5px;
  line-height: 60px;
  border-radius: 50%;
  position: absolute;
  background: #fff;
  text-align: center;
  font-family: "proxima_novabold";
}
.circle {
  width: 100%;
  float: left;
  height: 70px;
  position: relative;
}
.rt-statescontent {
  h3 {
    font-size: 18px;
    line-height: 16px;
    font-family: "proxima_novabold";
  }
  span {
    display: block;
    font-size: 14px;
    color: #696974;
    line-height: 16px;
    font-family: "proxima_novaregular";
  }
}
.rt-appliedstates {
  .circle-wrap .circle .mask .fill {
    background-color: var(--DodgerBlue);
  }
}
.rt-acceptedstates {
  .circle-wrap {
    background: rgba(61, 123, 152, 0.1);
  }
  .circle-wrap .circle .mask .fill {
    clip: rect(0px, 57px, 80px, 0);
    background-color: #3dd598;
  }
}
.rt-hiredstates {
  .circle-wrap {
    background: rgba(255, 151, 74, 0.1);
  }
  .circle-wrap .circle .mask .fill {
    clip: rect(0px, 15px, 80px, 0);
    background-color: #ff974a;
  }
}
.rt-jobpostedrates {
  width: 390px;
  float: right;
  .rt-userstates {
    padding: 0;
    li {
      width: 100%;
      float: left;
      padding: 30px 0;
      text-align: center;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      .circle-wrap .inside-circle {
        font-size: 22px;
      }
    }
    li + li {
      border-top: 1px solid #f1f1f5;
    }
  }
  .rt-jobposted .circle-wrap .circle .mask .fill {
    background: var(--DodgerBlue);
  }
  .rt-hiredstates .circle-wrap {
    background: rgba(61, 123, 152, 0.1);
  }
  .rt-hiredstates .circle-wrap .circle .mask .fill {
    background: #3dd598;
  }
  .rt-jobposted .circle-wrap .inside-circle i {
    color: var(--DodgerBlue);
  }
  .rt-hiredstates .circle-wrap .inside-circle i {
    color: #3dd598;
  }
}
.rt-newhiresrecentjobs {
  width: 100%;
  float: left;
  padding: 30px 0 0;
}
.rt-newhirings {
  float: left;
  width: 410px;
}
.rt-newhiresrecentjobs {
  .rt-themeboxhead {
    h2 {
      float: left;
    }
  }
}
.rt-btndots {
  float: right;
  cursor: pointer;
  border: none;
  background-color: white;
}
.rt-newhiredlists {
  width: 100%;
  float: left;
  height: 325px;
  overflow: auto;
  list-style: none;
  margin: 0 0 30px;
  li {
    width: 100%;
    float: left;
    padding: 15px 0;
    list-style-type: none;
  }
}
.rt-newhiredlists::-webkit-scrollbar {
  display: none;
}
.rt-newhirelist {
  width: 100%;
  float: left;
  position: relative;
}
.rt-newhiredimg,
.rt-hirednamedesig {
  display: inline-block;
  vertical-align: middle;
}
.rt-newhiredimg {
  width: 42px;
  height: 42px;
  overflow: hidden;
  margin: 0 15px 0 0;
  border-radius: 50%;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.rt-hirednamedesig {
  h3 {
    font-size: 14px;
    margin: 0 0 5px;
    color: #171725;
    line-height: 14px;
    font-family: "proxima_novabold";
  }
  span {
    display: block;
    font-size: 12px;
    color: #92929d;
    line-height: 12px;
  }
}
.rt-btnactions {
  right: 0;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.rt-btnaction {
  font-size: 16px;
  cursor: pointer;
  color: #92929d;
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
}
.rt-btnaction + .rt-btnaction {
  margin: 0 0 0 20px;
}
.rt-btnviewmore {
  clear: both;
  display: block;
  font-size: 12px;
  overflow: hidden;
  line-height: 55px;
  text-align: center;
  margin: 0 -25px -25px;
  margin: 25px -25px -25px;
  color: var(--DodgerBlue);
  border-top: 1px solid #f1f1f5;
}
/* =============================================
		Recent Jobs Styling
============================================= */
.rt-resentjobsbox {
  float: right;
  min-height: 484px;
  width: calc(100% - 440px);
}
.rt-recentjobsposts {
  width: 100%;
  float: left;
  .rt-themetableholder {
    .rt-themetable {
      margin: 0;
      padding: 0 10px;
      tbody {
        tr {
          border-radius: 20px;
          td {
            .rt-themedropdown {
              right: 20px;
              button.btn-success {
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}
.rt-themetableholder {
  width: 100%;
  float: left;
  height: 350px;
  overflow: auto;
}
.rt-themetableholder::-webkit-scrollbar {
  display: none;
}
.rt-themetable {
  margin: 0;
  padding: 0 10px;
  border-spacing: 0 15px;
  border-collapse: initial;
  thead {
    tr {
      th {
        border: 0;
        padding: 0 15px;
        text-align: left;
        overflow: hidden;
        line-height: 38px;
        background: #fafafb;
        vertical-align: middle;
        border-top: 0 !important;
        text-transform: uppercase;
        font-family: "proxima_novabold";
        &:first-child {
          border-radius: 10px 0 0 10px;
        }
        &:last-child {
          border-radius: 0 10px 10px 0;
        }
        span {
          font-size: 11px;
          color: #44444f;
          line-height: 13px;
        }
        ul {
          margin: 0;
          width: 100%;
          float: left;
          list-style: none;
          li {
            float: left;
            padding: 0 25px;
            list-style-type: none;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        border: 0;
        text-align: left;
        padding: 12px 15px;
        position: relative;
        vertical-align: middle;
        h3 {
          font-size: 14px;
          color: #171725;
          line-height: 14px;
          font-family: "proxima_novabold";
        }
        span {
          font-size: 14px;
          line-height: 14px;
        }
        time {
          display: block;
          font-size: 14px;
          color: #696974;
          line-height: 14px;
        }
        p {
          margin: 0;
          float: left;
          /* width: 290px; */
          font-size: 14px;
          /* overflow: hidden; */
          color: #696974;
          line-height: 18px;
          /* white-space: nowrap;
					text-overflow: ellipsis; */
        }
        ul {
          margin: 0;
          width: 100%;
          float: left;
          list-style: none;
          li {
            float: left;
            padding: 0 25px;
            position: relative;
            list-style-type: none;
            &:first-child {
              padding-left: 0;
              &:after {
                display: none;
              }
            }
            &:last-child {
              padding-right: 0;
            }
            &:after {
              left: 0;
              top: 50%;
              width: 1px;
              content: "";
              height: 48px;
              position: absolute;
              background: #f1f1f5;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-transform: translateY(-50%);
              transform: translateY(-50%);
            }
            i,
            span {
              display: inline-block;
              vertical-align: middle;
            }
            span {
              font-size: 14px;
              color: #171725;
              line-height: 15px;
              font-family: "proxima_novabold";
            }
            i {
              font-size: 14px;
              color: #272755;
              line-height: 14px;
              margin: 0 8px 0 0;
            }
          }
        }
      }
    }
  }
}
/* =============================================
		Job List Styling
============================================= */
.rt-joblistpage {
  width: 100%;
  float: left;
  .rt-pagetitle {
    h1 {
      float: left;
    }
    .rt-btnpostajob {
      float: right;
    }
  }
}
.rt-jobslistholder {
  width: 100%;
  float: left;
  position: relative;
}
.rt-jobslistsarea {
  float: left;
  width: calc(100% - 450px);
  width: 100%;
  // padding: 22px 5px 0;
  border-radius: 25px;
  // border: 1px solid #e2e2ea;
  .rt-themetableholder {
    height: calc(100vh - 375px);
  }
}
.show-jobdetail {
  .rt-jobslistsarea {
    width: calc(100% - 450px);
  }
}
.at-jobareahead {
  width: 100%;
  float: left;
  h3 {
    width: 100%;
    float: left;
    font-size: 16px;
    color: #696974;
    margin: 0 0 20px;
    line-height: 16px;
    padding: 0 0 0 20px;
    font-family: "proxima_novabold";
  }
}
.rt-formsearch {
  padding: 0 0 25px;
  fieldset {
    .form-group {
      margin: 0;
      padding: 0;
      background: none;
      i {
        left: 12px;
        right: auto;
      }
      input {
        font-size: 14px;
        padding: 10px 40px;
        background: #fff;
        border-radius: 10px;
      }
    }
  }
}
.rt-jobstable {
  border-spacing: 0 10px;
  thead {
    tr {
      th {
        background: none;
      }
    }
  }
  tbody {
    tr {
      border-radius: 20px;
      &:hover {
        -webkit-box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
        box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
      }
      td {
        background: #fff;
        padding: 16px 15px;
        .rt-themedropdown {
          // width: 100%;
          // float: left;
          // transform: none;
          // position: static !important;
          // .dropdown-menu{
          // top: 0 !important;
          // left: 0 !important;
          // }
        }
        &:first-child {
          position: static;
          border-radius: 20px 0 0 20px;

          .rt-themedropdown {
            width: 100%;
            float: left;
            transform: none;
            position: static !important;
            i,
            em {
              display: inline-block;
              vertical-align: middle;
            }
            // .dropdown-menu{
            // 	top: 0 !important;
            // 	left: 0 !important;
            // }
          }
        }
        &:last-child {
          border-radius: 0 20px 20px 0;
        }
        button {
          width: 100%;
          float: left;
          height: 25px;
          font-size: 10px;
          cursor: pointer;
          color: #92929d;
          text-align: right;
          position: relative;
          padding: 0 12px 0 0;
          &:before {
            right: 0;
            top: 50%;
            width: 1px;
            content: "";
            height: 48px;
            position: absolute;
            background: #f1f1f5;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
.rt-statusbuttonholder {
  width: 100%;
  float: left;
  position: relative;
  button {
    top: 50%;
    right: 0;
    border: 0;
    width: auto !important;
    height: auto !important;
    position: absolute !important;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.rt-statusselect {
  left: 0;
  top: 45px;
  float: left;
  padding: 5px;
  height: auto;
  width: 120px;
  display: none;
  background: #fff;
  position: absolute;
  span {
    float: left;
    clear: both;
    padding: 0 8px;
    cursor: pointer;
    line-height: 25px;
    background: #ccc;
    border-radius: 30px;
    i,
    em {
      font-size: 12px;
      font-style: normal;
      display: inline-block;
      vertical-align: middle;
      font-family: "proxima_novaregular";
    }
    i {
      margin: 0 5px 0;
    }
  }
  .rt-active {
    background: #e7f6ff;
    i,
    em {
      color: #19a6fb;
    }
  }
  .rt-deactivated {
    background: #f0f0f1;
    i {
      color: #92929d;
    }
    em {
      color: #696974;
    }
  }
  .rt-saved {
    background: #e6faf8;
    i {
      color: #3dd598;
    }
    em {
      color: #11ccb8;
    }
  }
  .rt-deleted {
    background: #ffebf0;
    i {
      color: #fc5a5a;
    }
    em {
      color: #fa4169;
    }
  }
}
.rt-jobpreviewarea {
  width: 100%;
  float: right;
  display: none;
  max-width: 420px;
  border-radius: 22px;
  padding: 20px 5px 5px;
  border: 1px solid #e2e2ea;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  .rt-btnactions {
    float: right;
    transform: none;
    position: static;
    padding: 0 0 15px;
    li {
      float: left;
      padding: 0 15px;
      button {
        font-size: 18px;
        cursor: pointer;
        color: #92929d;
        line-height: 18px;
      }
      .icon-delete {
        color: #fc5a5a;
      }
    }
  }
}
.show-jobdetail {
  .rt-jobpreviewarea {
    display: block;
  }
}
.rt-jobpreview {
  width: 100%;
  float: left;
  overflow: auto;
  background: #fff;
  border-radius: 20px;
  padding: 30px 5px 30px;
  height: calc(100vh - 318px);
}
.rt-jobpreview::-webkit-scrollbar {
  display: none;
}
.rt-johead {
  width: 100%;
  float: left;
  text-align: center;
  position: relative;
}
.rt-applicationscount {
  top: 0;
  right: 0;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  color: var(--DodgerBlue);
  font-family: "proxima_novaregular";
  em,
  span {
    display: inline-block;
    vertical-align: middle;
  }
  em {
    margin: 0 5px 0 0;
    font-style: normal;
  }
}
.rt-conpmanylogo {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto 30px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.rt-jobname {
  width: 100%;
  float: left;
  font-size: 20px;
  margin: 0 0 20px;
  color: #171725;
  line-height: 20px;
  span {
    display: block;
    margin: 5px 0 0;
  }
}
.rt-jobinfotags {
  width: 100%;
  max-width: 300px;
  list-style: none;
  overflow: hidden;
  text-align: center;
  margin: 0 auto 40px;
  li {
    padding: 4px;
    display: inline-block;
    list-style-type: none;
    vertical-align: middle;
    span {
      display: block;
      padding: 0 10px;
      font-size: 12px;
      line-height: 25px;
      text-align: center;
      border-radius: 30px;
      background: #f7f7f7;
      font-family: "proxima_novaregular";
      i,
      em {
        font-style: normal;
        display: inline-block;
        vertical-align: middle;
      }
      i {
        margin: 0 5px 0 0;
      }
    }
    .rt-bghourly {
      color: #990012;
      background: #f5e5e6;
    }
    .rt-bgonsite {
      color: #31d15b;
      background: #eafbee;
    }
    .rt-bgfulltime {
      color: #19a6fb;
      background: #e7f6ff;
    }
    .rt-bgsales {
      color: #9013fe;
      background: #f4e7ff;
    }
    .rt-bgprice {
      color: #ffa142;
      background: #fff5eb;
    }
  }
}
.rt-joblocation {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  max-width: 320px;
  overflow: hidden;
  position: relative;
  padding: 0 0 25px 30px;
  i {
    top: 0;
    left: 0;
    font-size: 22px;
    line-height: 22px;
    position: absolute;
    color: var(--DodgerBlue);
  }
}
.rt-jobaddress {
  width: 100%;
  float: left;
  text-align: left;
  h4 {
    font-size: 16px;
    margin: 0 0 5px;
    color: #171725;
    line-height: 16px;
    font-family: "proxima_novabold";
  }
  p {
    margin: 0 0 5px;
    font-size: 12px;
    color: #565656;
    line-height: 15px;
    font-family: "proxima_novaregular";
  }
  span {
    width: 100%;
    float: left;
    display: block;
    font-size: 15px;
    line-height: 15px;
    color: var(--DodgerBlue);
    font-family: "proxima_novaregular";
  }
}
.rt-jobmaparea {
  width: 100%;
  float: left;
  margin: 0 0 5px;
}
.rt-mapplaceholder {
  margin: 0;
  width: 100%;
  float: left;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.rt-jobinoarea {
  width: 100%;
  margin: 0 auto;
  max-width: 320px;
  overflow: hidden;
}
.rt-jobinbox {
  width: 100%;
  float: left;
  text-align: left;
  h4 {
    font-size: 20px;
    margin: 0 0 20px;
    color: #171725;
    line-height: 20px;
    font-family: "proxima_novaregular";
  }
  .rt-description {
    p {
      margin: 0;
      font-size: 14px;
      color: #3d3d3d;
      line-height: 22px;
      font-family: "proxima_novaregular";
    }
  }
  ul {
    width: 100%;
    float: left;
    font-size: 14px;
    list-style: none;
    color: #3d3d3d;
    line-height: 22px;
    font-family: "proxima_novaregular";
    li {
      width: 100%;
      float: left;
      padding: 12px 0;
      position: relative;
      line-height: inherit;
      list-style-type: none;
    }
    span {
      display: block;
      position: relative;
      padding: 0 0 0 15px;
      &:before {
        left: 0;
        top: 7px;
        width: 7px;
        height: 7px;
        content: "";
        position: absolute;
        border-radius: 50%;
        background: #d9d9d9;
      }
    }
  }
}
.rt-jobinbox + .rt-jobinbox {
  margin: 0px 0 0;
}
.rt-countdropdown {
  width: 100%;
  float: left;
  position: relative;
  ul {
    width: auto !important;
  }
}
.rt-themedropdown {
  // top: 50%;
  // right: 0;
  // z-index: 5;
  // position: absolute !important;
  // -webkit-transform: translateY(-50%);
  // -moz-transform: translateY(-50%);
  // -ms-transform: translateY(-50%);
  // -o-transform: translateY(-50%);
  // transform: translateY(-50%);
  button {
    border: 0;
    background: none;
    // padding: 0 !important;
    height: auto !important;
    font-size: 18px !important;
    line-height: 4px !important;
    &:after,
    &:before {
      display: none;
    }
    &:hover {
      color: inherit;
      background: none;
    }
    &:focus,
    &:active {
      color: inherit !important;
      background: none !important;
      box-shadow: none !important;
    }
    i {
      height: 4px;
      line-height: 4px;
    }
  }
}
.rt-themedropdownmenu {
  // width: 130px;
  background: #fff;
  // left: -25px !important;
  padding: 20px !important;
  text-align: left !important;
  border-radius: 15px !important;
  -webkit-box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
  box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
  a {
    margin: 0;
    width: 100%;
    float: left;
    display: block;
    padding: 0 20px;
    font-size: 14px;
    color: #171725;
    line-height: 14px;
    padding: 0 !important;
    font-family: "proxima_novabold";
    i,
    span {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      color: #272755;
      margin: 0 7px 0 0;
    }
    &:last-child {
      // color: #fc5a5a;
      // i{color: inherit;}
    }
    &:active {
      background: none;
    }
  }
  a + a {
    margin: 15px 0 0;
  }
}
.rt-bgdanger {
  i,
  span {
    color: #fc5a5a !important;
  }
}
.rt-status-bg-closed {
  background: #f9ecec !important;
  i,
  em {
    color: #fc5a5a !important;
  }
}
.rt-status-bg-saved {
  background: #f1f1f1 !important;
  i,
  em {
    color: grey !important;
  }
}
.rt-status-bg-posted {
  background: #ddfbef !important;
  i,
  em {
    color: #3dd598 !important;
  }
}
/* =============================================
		Create Job Styling
============================================= */
.rt-createjob,
.rt-createnewjobpage {
  width: 100%;
  float: left;
}
.rt-createnewjobpage .rt-pagetitle h1 {
  float: left;
  padding: 18px 0;
}
.rt-companynamelogo {
  float: right;
}
.rt-companynamelogo span,
.rt-companynamelogo img {
  display: inline-block;
  vertical-align: middle;
}
.rt-companynamelogo span {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  margin: 0 10px 0 0;
  padding: 0 15px 0 0;
}
.rt-companynamelogo span:before {
  top: 0;
  right: 0;
  width: 2px;
  content: "";
  height: 100%;
  position: absolute;
  background: #c6c6c6;
}
.rt-companynamelogo img {
  // width: 60px;
  height: 60px;
}
.rt-mandatorydot {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  background: #fc5a5a;
}
.rt-formcreatenewjob {
  fieldset {
    .form-group {
      margin: 0 0 30px;
      textarea {
        padding: 0;
        width: 100%;
        float: left;
        resize: none;
        height: 140px;
        font-size: 16px;
        border-radius: 0;
        line-height: 20px;
        background: #fff;
      }
      .rt-jobmaparea {
        margin: 0;
        .rt-mapplaceholder {
          height: 230px;
          overflow: hidden;
          #pac-card {
            margin: 0 0 15px;
          }
        }
      }
      .rt-select {
        &:after {
          font-size: 12px;
          color: #92929d;
          line-height: 30px;
          display: none;
        }
        select {
          padding: 0;
          height: 30px;
          font-size: 24px;
          border-radius: 0;
          line-height: 27px;
          background: #fff;
        }
      }
    }
  }
}
.rt-twocol {
  clear: both;
  // overflow: hidden;
  margin: 0 -15px 30px;
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  .form-group {
    float: left !important;
    margin: 0 15px !important;
    width: calc(50% - 30px) !important;
  }
}
.rt-twocoltwo {
  width: 50%;
  float: left;
  .form-group {
    margin: 0 15px 30px !important;
    &:last-child,
    &:nth-last-child(2) {
      margin-bottom: 0 !important;
    }
  }
}
.rt-formbtns {
  width: 100%;
  float: left;
  margin: 30px 0 0;
  text-align: center;
  .rt-btnlogin {
    padding: 0 !important;
    width: auto !important;
    float: none !important;
    height: auto !important;
    font-size: 15px !important;
    min-width: 200px !important;
    line-height: 50px !important;
    text-align: center !important;
    background: #fff !important;
    color: var(--DodgerBlue) !important;
    font-family: "proxima_novabold";
    border: 1px solid var(--DodgerBlue);
    span {
      color: inherit;
      position: static;
    }
    &:hover {
      color: #fff !important;
      background: var(--DodgerBlue) !important;
      border-color: var(--DodgerBlue) !important;
    }
  }
  .rt-btnlogin + .rt-btnlogin {
    margin: 0 0 0 30px;
  }
  .rt-btndelete {
    color: #fff;
    background: #fc5a5a;
    border-color: #fc5a5a;
    &:hover {
      color: #fff;
      background: #fc5a5a;
      border-color: #fc5a5a;
    }
  }
}
/* =============================================
		Application Page Styling
============================================= */
.rt-applicationspage {
  .rt-jobpreviewarea {
    .rt-jobpreview {
      padding: 0;
    }
    h2 {
      margin: 0;
      float: left;
      font-size: 24px;
      line-height: 24px;
      padding: 0 0 0 15px;
      font-family: "proxima_novabold";
    }
  }
}
.rt-headdropdown {
  float: left;
  position: relative;
  margin: -7px 0 0 20px;
  .rt-themedropdown {
    padding: 0;
    width: 100%;
    float: left;
    transform: none;
    line-height: 40px;
    border-radius: 8px;
    position: static !important;
    background: var(--DodgerBlue);
    button {
      width: 100%;
      float: left;
      font-size: 15px;
      color: #fff !important;
      font-family: "proxima_novabold";
      line-height: inherit !important;
      padding: 0 65px 0 10px !important;
      &:hover {
        color: #fff !important;
      }
      i {
        top: 50%;
        right: 15px;
        color: #fff;
        font-size: 10px;
        line-height: 10px;
        position: absolute;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    .rt-themedropdownmenu {
      width: 100%;
      min-width: auto;
      left: 0 !important;
      top: 40px !important;
      transform: none !important;
      background: var(--DodgerBlue);
      a {
        color: #fff;
        &:hover {
          background: none;
        }
      }
    }
  }
}
.rt-applicantpreviewarea {
  width: 100%;
  float: left;
  background: #fff;
  padding: 0 0 70px;
  position: relative;
  border-radius: 20px;
  height: calc(100vh - 318px);
}
.rt-applicantinfoarea {
  width: 100%;
  float: left;
  overflow: auto;
  background: #fff;
  position: relative;
  border-radius: 20px;
  padding: 30px 35px 30px;
  height: calc(100vh - 400px);
}
.rt-applicantinfoarea::-webkit-scrollbar {
  display: none;
}
.rt-applicanthead {
  width: 100%;
  float: left;
  padding: 0 0 40px;
}
.rt-appliantimg {
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 0 10px 0 0;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.rt-applicantnameloc {
  display: inline-block;
  vertical-align: middle;
  h3 {
    font-size: 14px;
    margin: 0 0 5px;
    color: #171725;
    line-height: 14px;
    font-family: "proxima_novabold";
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #696974;
    line-height: 14px;
    font-family: "proxima_novaregular";
  }
}
.rt-applicantdetail {
  width: 100%;
  float: left;
  .rt-description {
    p {
      margin: 0;
      font-size: 14px;
      color: #44444f;
      line-height: 22px;
      font-family: "proxima_novaregular";
    }
  }
}
.rt-applicantdetail + .rt-applicantdetail {
  padding: 20px 0 0;
}
.rt-applicantitle {
  width: 100%;
  float: left;
  padding: 0 0 10px;
  i,
  h4 {
    font-size: 14px;
    color: #000000;
    line-height: 14px;
    font-weight: bold;
    display: inline-block;
    // vertical-align: middle;
  }
  i {
    margin: 0 5px 0 10px;
  }
  h4 {
    margin: 0;
    font-family: "proxima_novaregular";
  }
}
.rt-applicantcontent {
  width: 100%;
  float: left;
  padding: 0 0 0 20px;
  h5 {
    margin: 0;
    font-size: 14px;
    color: #72727f;
    line-height: 14px;
    font-family: "proxima_novabold";
  }
}
.rt-skillholder {
  width: 100%;
  float: left;
  span {
    display: block;
    font-size: 14px;
    color: #44444f;
    line-height: 14px;
  }
  h5 {
    padding: 15px 0 0;
  }
}
.rt-skillholder + .rt-skillholder {
  padding: 20px 0 0;
}
.rt-pastworkinfo {
  width: 100%;
  float: left;
  position: relative;
  padding: 10px 0 0 35px;
}
.rt-pastworkinfo + .rt-pastworkinfo {
  margin: 50px 0 0;
}
.rt-workcount {
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  color: #fff;
  font-size: 14px;
  line-height: 32px;
  position: absolute;
  text-align: center;
  border-radius: 50%;
  background: #53d0ec;
  font-family: "proxima_novaregular";
}
.rt-workhead {
  width: 100%;
  float: left;
  padding: 0 0 15px;
  h5 {
    margin: 0;
    font-size: 14px;
    color: #171725;
    margin: 0 0 15px;
    line-height: 14px;
    font-family: "proxima_novabold";
    span {
      font-family: "proxima_novaregular";
    }
  }
  em {
    width: 100%;
    float: left;
    font-size: 14px;
    color: #171725;
    line-height: 14px;
    font-style: normal;
    font-family: "proxima_novaregular";
  }
}
.rt-btnsholder {
  width: 100%;
  float: left;
  bottom: 15px;
  text-align: center;
  position: absolute;
  .rt-btnlogin {
    border: 0 !important;
    width: auto !important;
    float: none !important;
    height: auto !important;
    padding: 0 35px !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 40px !important;
    box-shadow: none !important;
    span {
      position: static;
    }
  }
  .rt-btnlogin + .rt-btnlogin {
    margin: 0 0 0 10px;
  }
  .rt-bgaccept {
    background: #3dd598 !important;
  }
  .rt-bgsave {
    background: #ffc542 !important;
  }
  .rt-bgreject {
    background: #fc5a5a !important;
  }
}
.rt-applicantimgname {
  width: 100%;
  float: left;
}
.rt-applicantimg,
.rt-applicantimgname h4 {
  display: inline-block;
  vertical-align: middle;
}
.rt-applicantimg {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 10px 0 0;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.rt-applicantimgname h4 {
  margin: 0;
  font-size: 14px;
  color: #171725;
  line-height: 14px;
  font-family: "proxima_novabold";
}
.rt-applicationspage {
  .rt-jobstable {
    tbody {
      tr {
        td {
          span {
            float: left;
            width: "100%";
            display: block;
            font-size: 14px;
            overflow: hidden;
            color: #696974;
            line-height: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          button {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
.rt-selectresult {
  float: left;
  font-size: 12px;
  padding: 0 10px;
  line-height: 25px;
  text-align: center;
  margin: 0 45px 0 0;
  background: #000;
  border-radius: 30px;
  i,
  em {
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
  }
  i {
    margin: 0 10px 0 0;
  }
}
.rt-bgstatusyellow {
  color: #ffa142;
  background: #fff5eb;
}
.rt-bgstatusgreen {
  color: #11ccb8;
  background: #e6faf8;
}
.rt-bgstatusred {
  color: #fc5a5a;
  background: #ffeeee;
}
.rt-applicationspage {
  .rt-jobstable {
    tbody {
      tr {
        td {
          .rt-themedropdownmenu {
            width: 190px;
            a {
              span {
                float: none;
                width: auto;
                color: #171725;
                display: inline-block;
                vertical-align: middle;
              }
              &:last-child span {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}
/* =============================================
		Chat Page Styling
============================================= */
.rt-chat,
.rt-chatbox {
  width: 100%;
  float: left;
}
.rt-chatsidebar {
  width: 420px;
  float: left;
  border-radius: 25px;
  padding: 20px 5px 5px;
  border: 1px solid #e2e2ea;
}
.rt-sidebarhead {
  width: 100%;
  float: left;
  h3 {
    margin: 0;
    float: left;
    font-size: 16px;
    color: #696974;
    line-height: 16px;
    padding: 0 0 0 15px;
    font-family: "proxima_novabold";
  }
  .rt-formsearch {
    padding: 20px 0 0;
    border-bottom: 1px solid #d6dde6;
    fieldset {
      padding: 10px;
      background: #fff;
      border-radius: 20px 20px 0 0;
      .form-group {
        input {
          background: #f2f5f9;
        }
      }
    }
  }
}
.rt-btnfilter {
  float: right;
  display: block;
  font-size: 22px;
  cursor: pointer;
  line-height: 22px;
  margin: 0 15px 0 0;
}
.rt-chatusers {
  margin: 0;
  width: 100%;
  float: left;
  overflow: auto;
  list-style: none;
  background: #fff;
  height: calc(100vh - 360px);
  border-radius: 0 0 25px 25px;
  li {
    width: 100%;
    float: left;
    padding: 20px;
    background: #fff;
    list-style-type: none;
  }
  li + li {
    border-top: 1px solid #d6dde6;
  }
}
.rt-chatusers::-webkit-scrollbar {
  display: none;
}
.rt-user {
  width: 100%;
  float: left;
  cursor: pointer;
  position: relative;
  .rt-messagetime {
    left: auto;
  }
  .rt-userimg {
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin: 0 20px 0 0;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #f2f5f9;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
.rt-usernamemessage {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 70px);
  h3 {
    margin: 0 0 5px;
    font-size: 16px;
    color: #8e99aa;
    line-height: 16px;
    font-family: "proxima_novabold";
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #8e99aa;
    overflow: hidden;
    line-height: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.rt-unreadmessage {
  p,
  h3 {
    color: #000;
  }
}
.rt-messagetime {
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  font-family: "proxima_novathin";
}
.rt-chatpanel {
  float: right;
  // padding: 5px;
  background: #fff;
  border-radius: 25px;
  width: calc(100% - 450px);
  height: calc(100vh - 225px);
}
.rt-chathead {
  width: 100%;
  float: left;
  padding: 20px;
  position: relative;
  border-bottom: 1px solid #d6dde6;
}
.rt-btnjobcard {
  top: 50%;
  right: 20px;
  border: 0 !important;
  color: #fff !important;
  display: block !important;
  padding: 0 30px !important;
  font-size: 15px !important;
  line-height: 40px !important;
  position: absolute !important;
  border-radius: 8px !important;
  background: #3dd598 !important;
  font-family: "proxima_novabold" !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  &:hover {
    color: #fff;
    text-decoration: none;
  }
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
.rt-userjobinfo {
  float: left;
}
.rt-jobuserimg {
  float: left;
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 20px 0 0;
  border: 1px solid #f2f5f9;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.rt-jobuserinfo {
  float: left;
  width: calc(100% - 95px);
  h4 {
    color: #000;
    font-size: 22px;
    margin: 0 0 8px;
    line-height: 22px;
    font-family: "proxima_novabold";
  }
  span {
    clear: both;
    display: block;
    font-size: 14px;
    color: #696974;
    line-height: 14px;
  }
  ul {
    width: 100%;
    float: left;
    font-size: 12px;
    list-style: none;
    color: #696974;
    line-height: 12px;
    padding: 10px 0 0;
    li {
      float: left;
      padding: 0 10px;
      position: relative;
      list-style-type: none;
      &:first-child {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
      &:last-child {
        padding-right: 0;
      }
      &:before {
        top: 50%;
        left: -2px;
        width: 4px;
        height: 4px;
        content: "";
        border-radius: 50%;
        position: absolute;
        background: #696974;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      span {
        display: block;
        color: #696974;
      }
    }
  }
}
.rt-chatholder {
  width: 100%;
  float: left;
  position: relative;
  padding: 5px 5px 105px;
}
.rt-chatmessages {
  width: 100%;
  float: left;
  padding: 15px;
  overflow: auto;
  height: calc(100vh - 450px);
}
.rt-chatmessages::-webkit-scrollbar {
  display: none;
}
.rt-message {
  clear: both;
  float: right;
  position: relative;
  padding: 0 0 0 60px;
  p {
    margin: 0;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #696974;
    line-height: 20px;
    background: #f2f5f9;
    border-radius: 22px 0 22px 22px;
  }
}
.rt-messagetime {
  top: 0;
  left: 0;
  position: absolute;
}
.rt-sendermessage {
  float: left;
  padding: 0 60px 0 0;
  .rt-messagetime {
    right: 0;
    left: auto;
  }
  p {
    color: #fff;
    background: var(--DodgerBlue);
    border-radius: 0 22px 22px 22px;
  }
}
.rt-chatfile {
  float: left;
  clear: both;
  width: 320px;
  margin: 10px 0 0;
  position: relative;
  border-radius: 22px;
  padding: 20px 70px 20px 20px;
  background: var(--DodgerBlue);
}
.rt-btndownloadfile {
  top: 50%;
  right: 20px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  line-height: 30px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.rt-filenamesize {
  width: 100%;
  float: left;
  h3 {
    color: #fff;
    font-size: 16px;
    margin: 0 0 10px;
    overflow: hidden;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: "proxima_novabold";
  }
  span {
    width: 100%;
    float: left;
    display: block;
    font-size: 12px;
    margin: 0 0 10px;
    line-height: 12px;
    font-family: "proxima_novabold";
    color: rgba(255, 255, 255, 0.5);
  }
}
.rt-fileprogressbar {
  width: 100%;
  float: left;
  height: 8px;
  padding: 2px;
  background: #fff;
  position: relative;
  border-radius: 30px;
  &:after {
    top: 50%;
    left: 2px;
    height: 4px;
    content: "";
    width: 150px;
    position: absolute;
    border-radius: 30px;
    background: var(--DodgerBlue);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.rt-formsendchat {
  left: 0;
  bottom: 0;
  padding: 5px;
  position: absolute;
  fieldset {
    background: #f2f5f9;
    border-radius: 0 0 20px 20px;
    .form-group {
      margin: 0;
      padding: 10px;
      background: none;
      position: relative;
      > i {
        left: 35px;
        right: auto;
      }
      textarea {
        width: 100%;
        float: left;
        resize: none;
        height: 60px;
        font-size: 16px;
        color: #696974;
        background: #fff;
        padding: 20px 60px;
        border-radius: 0 0 10px 10px;
        font-family: "proxima_novaregular";
      }
    }
  }
}
.rt-attachfiles {
  top: 50%;
  right: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  input[type="file"] {
    display: none;
  }
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  label {
    margin: 0;
    cursor: pointer;
    i {
      transform: none !important;
      position: static !important;
      color: var(--DodgerBlue) !important;
    }
  }
}
.rt-thememodal.rt-modaljob {
  .modal-dialog {
    max-width: 420px;
    .modal-content {
      padding: 0;
      .modal-body {
        padding: 0;
        .rt-jobpreviewarea {
          width: 100%;
          float: left;
        }
      }
    }
  }
}
/* =============================================
		Candidates Page Styling
============================================= */
.rt-candidatespage {
  .rt-jobpreviewarea {
    h2 {
      margin: 0;
      float: left;
      font-size: 24px;
      line-height: 24px;
      padding: 0 0 0 15px;
      font-family: "proxima_novabold";
    }
    .rt-applicantpreviewarea {
      padding: 0;
      .rt-applicantinfoarea {
        height: calc(100vh - 320px);
      }
    }
  }
  .rt-jobstable {
    min-width: 1200px;
    tbody {
      tr {
        td {
          .rt-themedropdown {
            right: 0;
            .rt-themedropdownmenu {
              width: 200px;
            }
          }
        }
      }
    }
  }
  .show-candidatedetail {
    .rt-jobslistsarea {
      width: calc(100% - 450px);
    }
    .rt-jobpreviewarea {
      display: block;
    }
  }
}

/* =============================================
		Theme Modal Styling
============================================= */
.rt-thememodal {
  .modal-dialog {
    width: 100%;
    overflow: hidden;
    max-width: 400px;
    .modal-content {
      border: 0;
      width: 100%;
      float: left;
      padding: 20px;
      border-radius: 22px;
      .modal-body {
        width: 100%;
        float: left;
        padding: 50px 0;
        p {
          margin: 0;
          width: 100%;
          float: left;
          color: #000;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}
.rt-modalheader {
  width: 100%;
  float: left;
  position: relative;
  border: 0 !important;
  padding: 0 !important;
  display: block !important;
  .modal-title {
    margin: 0;
    color: #000;
    font-size: 24px;
    line-height: 24px;
    font-family: "proxima_novabold";
  }
  .close {
    right: 0;
    top: -2px;
    opacity: 1;
    z-index: 3;
    float: none;
    position: absolute;
    padding: 0 !important;
    margin: 0 !important;
    &:focus,
    &:active {
      border: 0;
      box-shadow: none;
    }
  }
}
.modal-backdrop {
  background: rgba(32, 48, 73, 0.85) !important;
}
.modal-backdrop.show {
  opacity: 1 !important;
}
.rt-btndelete {
  width: 100%;
  float: left;
  border: 0 !important;
  padding: 0 !important;
  color: #fff !important;
  font-size: 15px !important;
  line-height: 50px !important;
  text-align: center !important;
  border-radius: 8px !important;
  background: #fc5a5a !important;
  font-family: "proxima_novabold" !important;
  &:focus {
    box-shadow: none !important;
  }
}
/* =============================================
		Profile Page Styling
============================================= */
.rt-themetabs,
.rt-profilepage {
  width: 100%;
  float: left;
}
.rt-themenavtabs {
  width: 100%;
  float: left;
  font-size: 24px;
  list-style: none;
  line-height: 24px;
  margin: 0 0 20px !important;
  font-family: "proxima_novabold";
  li {
    float: left;
    padding: 0 30px;
    line-height: initial;
    list-style-type: none;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    button {
      color: #ccc;
      display: block;
      padding: 0 0 10px;
      position: relative;
      line-height: initial;
      transition: var(--transition);
      &:before {
        left: 0;
        bottom: 0;
        height: 0;
        width: 100%;
        content: "";
        position: absolute;
        border-radius: 30px;
        background: var(--DodgerBlue);
        transition: var(--transition);
      }
    }
    &:hover {
      button {
        color: #000;
        &:before {
          height: 4px;
        }
      }
    }
  }
  .rt-active {
    button {
      color: #000;
      &:before {
        height: 4px;
      }
    }
  }
}
.rt-profiletab,
.rt-tabscontent {
  width: 100%;
  float: left;
}
.rt-profiletab {
  position: relative;
  padding: 0 450px 0 0;
}
.rt-profilebox {
  width: 100%;
  float: left;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
}
.rt-updatepaddword {
  margin: 30px 0 0;
}
.rt-profileboxhead {
  width: 100%;
  float: left;
  padding: 0 0 30px;
  h3 {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    color: var(--DodgerBlue);
    font-family: "proxima_novabold";
  }
}
.rt-usermanagementtab {
  width: 100%;
  float: left;
  position: relative;
  .rt-allusers {
    .rt-profileboxhead {
      h3 {
        float: left;
      }
      .rt-btnpostajob {
        float: right;
      }
    }
  }
}
.rt-showuserprofile {
  padding: 0 450px 0 0;
}
.rt-btndeleteuser {
  width: 200px;
  color: #fff;
  display: block;
  margin: 0 auto;
  font-size: 14px;
  line-height: 50px;
  border-radius: 8px;
  text-align: center;
  background: #fc5a5a;
  font-family: "proxima_novabold";
}
.rt-profileusers {
  width: 100%;
  float: left;
  .rt-jobslistsarea {
    border: 0;
    padding: 0;
    width: 100%;
    float: left;
    .at-jobareahead {
      h3 {
        padding: 0;
      }
    }
    .rt-formsearch {
      fieldset {
        .form-group {
          i {
            left: 0;
          }
        }
      }
    }
    .rt-jobstable {
      thead {
        background: #f2f5f9;
      }
      tbody {
        tr {
          td {
            > span {
              font-size: 14px;
              color: #696974;
              line-height: 14px;
            }
            border: 1px solid #f2f5f9;
            &:first-child {
              border-right: 0;
              span {
                font-size: 14px;
                color: #171725;
                line-height: 14px;
                font-family: "proxima_novabold" !important;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              border-right: 0;
              border-left: 0;
            }
            &:nth-child(4) {
              border-left: 0;
            }
          }
        }
      }
    }
  }
}
.rt-userinfoinput {
  width: 100%;
  float: left;
  .form-group {
    width: 100%;
    float: left;
    margin: 0 0 30px;
    text-align: left;
    position: relative;
    background: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1.5px solid #f2f5f9;
    i {
      top: 50%;
      z-index: 2;
      right: 20px;
      color: #ccc;
      font-size: 20px;
      line-height: 20px;
      position: absolute;
      transform: translateY(-50%);
    }
    label {
      width: 100%;
      float: left;
      color: #505050;
      font-size: 14px;
      line-height: 14px;
    }
    input {
      padding: 0;
      width: 100%;
      height: auto;
      color: #000;
      font-size: 24px;
      background: none;
      border-radius: 0;
      font-family: "proxima_novaregular";
    }
  }
}
.rt-createnewuser {
  width: 100%;
  float: left;
  position: relative;
  padding: 0 450px 0 0;
}
.rt-createuserhead {
  width: 100%;
  float: left;
  margin: 0 0 30px;
  .rt-onoffswitch {
    input[type="checkbox"] + label {
      margin: 0 0 0 55px;
      span {
        top: -3px;
        left: -55px;
        font-size: 16px;
        color: #696974;
        line-height: 16px;
        position: absolute;
      }
    }
  }
}
.rt-useradddate {
  float: left;
  span,
  strong {
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
  }
  strong {
    color: var(--DodgerBlue);
  }
}
.rt-billingtab {
  width: 100%;
  float: left;
  position: relative;
  padding: 0 0 0 0;
}
.rt-packageplans {
  width: 100%;
  float: left;
  ul {
    clear: both;
    margin: 0 -15px;
    list-style: none;
    &:after {
      clear: both;
      content: "";
      display: block;
    }
    li {
      float: left;
      width: 33.33%;
      padding: 0 15px;
      list-style-type: none;
    }
  }
}
.rt-packageplan {
  width: 100%;
  float: left;
  &:hover {
    label {
      border-color: #fff;
      -webkit-box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
      box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
    }
  }
  .rt-radio {
    input[type="radio"] + label {
      margin: 0;
      width: 100%;
      float: left;
      padding: 20px;
      position: relative;
      background: #fff;
      border-radius: 10px;
      border: 3px solid #f2f5f9;
      transition: var(--transition);
      &:hover {
        border-color: #fff;
      }
    }
    input[type="radio"]:checked + label {
      border-color: #fff;
      -webkit-box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
      box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
    }
    input[type="radio"] + label:before {
      top: 10px;
      left: auto;
      right: 10px;
      content: "";
      width: 18px;
      height: 18px;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      border-radius: 50%;
      font-family: "icomoon";
    }
    input[type="radio"]:checked + label:before {
      color: #fff;
      content: "\ec39";
      background: #11ccb8;
    }
  }
  .rt-description {
    padding: 20px 0;
    p {
      margin: 0;
      font-size: 16px;
      color: #696974;
      line-height: 26px;
      span {
        display: block;
      }
    }
  }
}
.rt-planname {
  float: left;
  color: #fff;
  font-size: 16px;
  min-width: 100px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  background: #f7f7f7;
  font-family: "proxima_novabold";
  -webkit-box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
  box-shadow: 0 0 15px 0 rgba(37, 116, 255, 0.3);
}
.rt-basicplan {
  .rt-planname {
    background: #11ccb8;
  }
}
.rt-proplan {
  .rt-planname {
    background: var(--DodgerBlue);
  }
}
.rt-propremium {
  .rt-planname {
    background: #ffa142;
  }
}
.rt-packageprice {
  width: 100%;
  float: left;
  strong {
    display: block;
    font-size: 20px;
    line-height: 20px;
    font-family: "proxima_novabold";
    sub {
      bottom: 0;
      color: #ccc;
    }
  }
}
.rt-basicplan {
  .rt-packageprice {
    strong {
      color: #11ccb8;
    }
  }
}
.rt-proplan {
  .rt-packageprice {
    strong {
      color: var(--DodgerBlue);
    }
  }
}
.rt-propremium {
  .rt-packageprice {
    strong {
      color: #ffa142;
    }
  }
}
.rt-choosbilling {
  width: 100%;
  float: left;
  margin: 35px 0 0;
  ul {
    clear: both;
    margin: 0 -15px;
    &:after {
      clear: both;
      content: "";
      display: block;
    }
    li {
      width: 50%;
      float: left;
      padding: 0 15px;
      label {
        em {
          float: right;
          color: #000;
          font-size: 20px;
          line-height: 20px;
          font-style: normal;
          sub {
            bottom: 0;
            color: #ccc;
          }
        }
      }
    }
  }
}
.rt-paymenthistory {
  margin: 30px 0 0;
}

.rt-paymentmethodoption {
  width: 100%;
  float: left;
  .rt-radio {
    input[type="radio"] + label {
      margin: 0;
      width: 100%;
      float: left;
      background: #fff;
      position: relative;
      border-radius: 10px;
      border: 1px solid #f2f5f9;
      padding: 15px 20px 15px 40px;
      &:before {
        top: 50%;
        left: 15px;
        width: 18px;
        content: "";
        height: 18px;
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        font-family: "icomoon";
        border: 1px solid #ccc;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      span {
        color: #ccc;
        font-size: 16px;
        line-height: 16px;
        font-family: "proxima_novabold";
      }
      i {
        top: 50%;
        right: 20px;
        color: #ccc;
        font-size: 25px;
        line-height: 25px;
        position: absolute;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    input[type="radio"]:checked + label {
      i,
      span {
        color: #000;
      }
      &:before {
        color: #fff;
        content: "\ec39";
        background: #11ccb8;
        border-color: #11ccb8;
      }
    }
  }
}
.rt-paymentmethodoption + .rt-paymentmethodoption {
  margin: 10px 0 0;
}
.rt-carddetailarea {
  width: 100%;
  float: left;
  margin: 10px 0 0;
}
.rt-creditcard {
  width: 100%;
  float: left;
  overflow: hidden;
  border-radius: 25px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.rt-usercardinfo {
  width: 100%;
  float: left;
  margin: 10px 0 0;
}
.rt-profilebuttons {
  top: 0;
  right: 0;
  width: 420px;
  position: absolute;
}
.rt-profilenotifications {
  width: 100%;
  float: left;
}
.rt-notificationholder {
  width: 100%;
  float: left;
}
.rt-notihead,
.rt-notification {
  width: 100%;
  float: left;
}
.rt-notihead {
  width: auto;
  float: none;
  margin: 0 -30px;
  overflow: hidden;
  padding: 0 30px 15px;
  border-bottom: 1px solid #f2f5f9;
  h4 {
    margin: 0;
    color: #000;
    font-size: 16px;
    line-height: 16px;
    font-family: "proxima_novabold";
  }
}
.rt-notification {
  ul {
    margin: 0;
    width: 100%;
    float: left;
    padding: 10px 0;
    list-style: none;
    li {
      width: 100%;
      float: left;
      padding: 10px 0;
      list-style-type: none;
      h5 {
        margin: 0;
        float: left;
        font-size: 16px;
        color: #696974;
        line-height: 16px;
      }
    }
  }
}
.rt-onoffswitch {
  float: right;
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    margin: 0;
    width: 35px;
    height: 16px;
    cursor: pointer;
    background: #fff;
    position: relative;
    border-radius: 30px;
    border: 1.5px solid #ccc;
    &:before {
      top: -4px;
      left: -2px;
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      position: absolute;
      border-radius: 50%;
      background: #fff;
      border: 1.5px solid #ccc;
    }
  }
  input[type="checkbox"]:checked + label {
    background: var(--DodgerBlue);
    border-color: var(--DodgerBlue);
    &:before {
      left: auto;
      right: -2px;
      border-color: var(--DodgerBlue);
    }
  }
}
.rt-updatebtnholder {
  width: 100%;
  float: left;
  margin: 30px 0 0;
  text-align: center;
  button {
    margin: 0 15px;
    font-size: 14px;
    padding: 0 20px;
    min-width: 195px;
    line-height: 50px;
    background: #fff;
    text-align: center;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    color: var(--DodgerBlue);
    transition: var(--transition);
    font-family: "proxima_novabold";
    border: 1px solid var(--DodgerBlue);
    &:hover {
      color: #fff;
      background: var(--DodgerBlue);
    }
  }
}
.rt-formprofile {
  fieldset {
    width: 100%;
    float: left;
    position: relative;
    padding: 0 250px 0 0;
    .form-group {
      margin: 0 0 30px;
      border: 1.5px solid #f2f5f9;
    }
    .rt-updatebtnholder {
      text-align: left;
    }
  }
}
.rt-changeprofileimage {
  top: 0;
  right: 0;
  width: 220px;
  padding: 20px;
  position: absolute;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f2f5f9;
}
.rt-userprofileimg {
  width: 170px;
  height: 170px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.rt-changeimage {
  width: 100%;
  float: left;
  padding: 20px 0 0;
  input[type="file"] {
    display: none;
  }
  label {
    margin: 0;
    width: 100%;
    float: left;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    border-radius: 8px;
    background: var(--DodgerBlue);
    font-family: "proxima_novabold";
  }
}
.rt-addressinput {
  clear: both;
  overflow: hidden;
  float: none !important;
  width: auto !important;
  margin: 0 -250px 0 0 !important;
}
.rt-formpassword {
  fieldset {
    .form-group {
      margin: 0 0 30px;
      border: 1.5px solid #f2f5f9;
    }
  }
}
.rt-twocols {
  clear: both;
  margin: 0 -15px;
  overflow: hidden;
  .form-group {
    margin: 0 15px !important;
    width: calc(50% - 30px) !important;
  }
}
.rt-paymenthistories {
  width: 100%;
  float: left;
  .rt-jobstable {
    thead {
      background: #f2f5f9;
    }
    tbody {
      tr {
        td {
          font-size: 14px;
          line-height: 14px;
          line-height: 20px;
          padding: 10px 15px;
          border-radius: 30px;
          border-radius: 0 !important;
          border: 1px solid #f2f5f9;
          &:first-child {
            border-right: 0;
            border-radius: 30px 0 0 30px !important;
          }
          &:nth-child(2),
          &:nth-child(3) {
            border-right: 0;
            border-left: 0;
          }
          &:last-child {
            border-left: 0;
            border-radius: 0 30px 30px 0 !important;
          }
          span {
            float: left;
          }
          button {
            padding: 0;
            width: auto;
            float: right;
            height: auto;
            font-size: 22px;
            line-height: 22px;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}
.rt-tableprice {
  font-family: "proxima_novabold";
}
.show-dropdown {
  display: block;
}
.rt-staturresult {
  float: left;
  padding: 0 8px;
  line-height: 25px;
  border-radius: 30px;
  background: #f7f7f7;
  i,
  em {
    margin: -3px 0 0;
    font-size: 12px;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
  }
  i {
    margin: -3px 5px 0 0;
  }
}
.rt-bgactive {
  background: #e7f6ff;
  i,
  em {
    color: #19a6fb;
  }
}
.rt-bgdeactivated {
  background: #f0f0f1;
  i,
  em {
    color: #696974;
  }
}
.rt-bgsaved {
  background: #e6faf8;
  i,
  em {
    color: #11ccb8;
  }
}
.rt-bgdeleted {
  background: #ffebf0;
  i,
  em {
    color: #fa4169;
  }
}
.rt-usercardinfo {
  .rt-twocols {
    .form-group {
      float: left;
      text-align: left;
      position: relative;
      background: #fff;
      padding: 10px 20px;
      border-radius: 10px;
      border: 1.5px solid #f2f5f9;
      label {
        width: 100%;
        float: left;
        color: #505050;
        font-size: 14px;
        line-height: 14px;
      }
      input {
        padding: 0;
        width: 100%;
        height: auto;
        color: #000;
        font-size: 24px;
        background: none;
        border-radius: 0;
        font-family: "proxima_novaregular";
      }
      i {
        top: 50%;
        z-index: 2;
        right: 20px;
        color: #ccc;
        font-size: 20px;
        line-height: 20px;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
}
.chart-inner-text {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding-top: 40%;
  text-align: center;
  font-size: 13px;
}
.readonly {
  min-height: 100px;
  width: 100%;
  border-radius: 0;
  background: transparent;
  padding: 0px;
  resize: none;
  font-weight: 500 !important;
}

.chart-active-job {
  width: 70px !important;
}
.react-select__indicators {
  display: none;
}
.c-db {
  color: var(--DodgerBlue);
}
.rt-btnholder {
  width: 100%;
  float: left;
  margin: 15px 0 0;
  text-align: center;
  .rt-btndeleteuser {
    margin: 0 10px;
    display: inline-block;
    vertical-align: middle;
    width: calc(50% - 20px);
  }
}
.dropdown-toggle.btn.btn-success {
  background: none !important;
  border-color: none !important;
}
.rt-salarytypeholder {
  clear: both;
  margin: 0 -15px 30px;
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  .form-group {
    float: left !important;
    margin: 0 15px !important;
    width: calc(33.33% - 30px) !important;
  }
}
.rt-ishourlyrate {
  .form-group {
    width: calc(50% - 30px) !important;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.application_list_page {
  width: 250px;
  margin: -7px 0 0 20px;
  .select__input {
    input {
      height: auto;
    }
  }
  .select__control {
    background: #2574ff;
    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
    .select__single-value,
    .select__placeholder {
      color: #fff;
    }
  }
  .select__indicator {
    svg {
      fill: #fff;
    }
  }
  .select__menu {
    margin-top: 0;
  }
}
.auth_header {
  margin-bottom: 30px;
  background: #fff;
  .login_header {
    padding: 10px 20px;
    box-shadow: 0 0 4px 2px lightgrey;
    img {
      width: 100px;
    }
  }
}
.auth_footer {
  margin: 40px 0;
  text-align: center;
}
.editor_wrapper {
  padding: 0 10px;
  border: 1px solid #f1f1f1;
  margin-top: -6px;
}
.rt-formtheme {
  fieldset {
    .form-group.mix_fields_wrapper {
      padding: 0;
      background: none;
    }
  }
}
.rt-inputiconholder {
  .public-DraftEditor-content {
    min-height: 200px;
    max-height: 300px;
  }
}
.job_down_arrow_wrapper {
  i {
    position: relative;
    top: 10px;
  }
}
#job_list_wrapper {
  height: auto;
  min-height: 400px;
  .job_item_col {
    padding: 0;
    border-radius: 20px;
  }
  thead {
    th {
      width: 30%;
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 55%;
      }
    }
  }
  .job_item_wrapper {
    position: relative;
    .arrow_dropdown {
      position: absolute;
      width: 15%;
      left: 0;
      top: 20px;
      z-index: 1000;
    }
    .dropdown {
      position: absolute;
      top: 30px;
      right: 20px;
    }
    tr {
      cursor: pointer;
    }
    td {
      background: transparent;
      width: 30%;
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 55%;
      }
    }
    @media (max-width: 600px) {
      td {
        width: 100% !important;
      }
      .arrow_dropdown {
        width: 80%;
      }
    }
  }
}

.rt-joblistpage.show-applicantdetail .rt-jobslistsarea {
  width: 100%;
}
#application_list {
  height: auto;
  .rt-jobpreviewarea {
    max-width: unset;
    border: none;
  }
  thead {
    th {
      width: 20%;
    }
  }
  .rt-btnactions {
    display: none;
  }
  .rt-applicantinfoarea {
    height: auto;
  }
  .rt-themetableholder {
    height: auto;
  }
  .rt-applicantitle {
    h4 {
      font-size: 18px;
    }
  }
  h5 {
    font-size: 16px;
  }
  .rt-jobstable tbody tr:hover {
    box-shadow: none;
    .application_item_outer_wrapper {
      box-shadow: 0 0 15px 0 lightgray;
    }
  }
  .skill_item {
    * {
      display: block;
      clear: both;
    }
    margin-bottom: 30px;
    h5 {
      margin-top: 10px;
    }
  }
  .applicant_exp_item {
    position: relative;
    overflow: hidden;
    padding-left: 55px;
    padding-top: 10px;
    margin-bottom: 30px;
    .exp_num {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #00d3f0;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      line-height: 40px;
      text-align: center;
    }
    p {
      display: block;
      clear: both;
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .application_item_col {
    border-radius: 20px;
    padding: 0;
    background: none;
    .application_detail {
      .rt-jobpreviewarea {
        float: none;
        height: auto;
        padding-top: 0;
        position: inherit;
        .rt-applicantinfoarea {
          padding-top: 10px;
        }
        .rt-applicantinfoarea,
        .rt-applicantpreviewarea {
          float: none;
          height: auto;
        }
      }
    }
    .application_item_outer_wrapper {
      background: #fff;
      border-radius: 20px;
    }

    .application_item_wrapper {
      position: relative;
      background: url(../images/gray-down-arrow.png) no-repeat calc(100% - 20px)
        center/12px transparent;
      tr {
        cursor: pointer;
        td {
          width: 20%;
          background: transparent;
          @media (max-width: 800px) {
            width: 100%;
          }
        }
      }
      .dropdowns {
        max-width: 230px;
        position: absolute;
        top: 32px;
        right: 40px;
      }
    }

    .employee_item_wrapper {
      position: relative;
      tr {
        // cursor: pointer;
        td {
          width: 10%;
          @media (max-width: 800px) {
            width: 100%;
          }
        }
      }
      .dropdowns {
        max-width: 230px;
        position: absolute;
        top: 32px;
        right: 40px;
      }
    }
  }
  .rt-btnsholder {
    .rt-btnlogin {
      width: auto !important;
      display: inline-block !important;
      padding: 17px 30px !important;
      margin: 0 10px !important;
      span {
        color: #fff;
        font-size: 16px;
      }
    }
    @media (min-width: 569px) {
      bottom: -70px;
      text-align: left;
    }
    @media (max-width: 568px) {
      bottom: -60px;
      text-align: left;
      .rt-btnlogin {
        padding: 10px 15px !important;
        margin: 0 5px !important;
        span {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.show-applicantdetail {
  #application_list {
    .application_item_col {
      .application_item_outer_wrapper.opened {
        margin-bottom: 80px;
      }
      .application_item_wrapper {
        background-image: url(../images/gray-up-arrow.png);
      }
      .application_detail {
        .rt-jobpreviewarea {
          .rt-applicantpreviewarea {
            float: none;
            border-top: 1px solid lightgray;
            height: auto;
            border-radius: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
@import "responsive.scss";
